import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from './plugins/vuetify';
import store from "./service/support/store";
import Auth from '@aws-amplify/auth';
import * as awsVars from './config/aws-exports';
let AWSConfig = awsVars.default(process.env);
import VueToastr from "vue-toastr";
import {EventBus} from './service/support/event-bus';
import LazyLoadDirective from "./components/LazyLoadDirective";
const AWS = require('aws-sdk');

import BaseContainerAuth from "./components/BaseContainerAuth";
import BaseContainerUnauth from "./components/BaseContainerUnauth";
import PopupDialog from "@/components/PopupDialog";

//console.log(JSON.stringify(process.env));
//AWS.config.logger = console;

Vue.use(VueToastr);
Auth.configure(AWSConfig);

//Add sign out to profile menu
store.state.pageProfileItems.push({
	icon: "fa-sign-out", text: "Sign Out", click: (item, event) => {
		store.state.cache = {};
		Auth.signOut({ global: true })
			.then(data => {
				EventBus.$emit('authState', {
					status: 'signedOut'
				});
			})
			.catch(err => {
				console.log(err);
				Auth.signOut();
			});
	}
});

//store.state.profileItems.unshift({icon: 'mdi-help-circle', text: 'Help', url: "https://help.zapaportal.com", newTab: true});
store.state.profileItems.push({icon: 'mdi-star', text: 'Upgrade to Pro Today!', modal:"upgrade", access: "guestUpgrade"});
store.state.profileItems.push({icon: 'mdi-folder-multiple', text: 'Dashboard', url: "/vaults"});
store.state.profileItems.push({icon: 'mdi-folder-plus', text: 'New Portal', modal: "newportal", access: "member" });
store.state.profileItems.push({icon: 'mdi-cog', text: 'Settings', url: "/settings", access: "admin"});
store.state.profileItems.push({icon: 'mdi-message', text: 'Submit Feedback', modal: "feedback"});

Vue.component('base-auth', BaseContainerAuth);
Vue.component('base-unauth', BaseContainerUnauth);
Vue.component('popup', PopupDialog);

Vue.config.productionTip = false;
Vue.config.performance = true;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");

Vue.directive('admin', {
	bind: function (el, binding, vnode) {
		let cognitoUser = vnode.context.$store.state.cognito;
		if (cognitoUser && cognitoUser.attributes['custom:mutableUserType'] !== "admin") {
			el.style.display = 'none';
		}
	},
	update: function (el, binding, vnode) {
		let cognitoUser = vnode.context.$store.state.cognito;
		if (cognitoUser && cognitoUser.attributes['custom:mutableUserType'] !== "admin") {
			el.style.display = 'none';
		} else {
			el.style.display = '';
		}
	}
});

Vue.directive('member', {
	bind: function (el, binding, vnode) {
		let cognitoUser = vnode.context.$store.state.cognito;
		if (cognitoUser && (cognitoUser.attributes['custom:mutableUserType'] !== "admin" && cognitoUser.attributes['custom:mutableUserType'] !== "member")) {
			el.style.display = 'none';
		}
	},
	update: function (el, binding, vnode) {
		let cognitoUser = vnode.context.$store.state.cognito;
		if (cognitoUser && (cognitoUser.attributes['custom:mutableUserType'] !== "admin" && cognitoUser.attributes['custom:mutableUserType'] !== "member")) {
			el.style.display = 'none';
		} else {
			el.style.display = '';
		}
	}
});

Vue.directive('guest', {
	bind: function (el, binding, vnode) {
		let cognitoUser = vnode.context.$store.state.cognito;
		if (cognitoUser && cognitoUser.attributes['custom:mutableUserType'] !== "guest") {
			el.style.display = 'none';
		}
	},
	update: function (el, binding, vnode) {
		let cognitoUser = vnode.context.$store.state.cognito;
		if (cognitoUser && cognitoUser.attributes['custom:mutableUserType'] !== "guest") {
			el.style.display = 'none';
		} else {
			el.style.display = '';
		}
	}
});

Vue.directive('enterprise', {
	bind: function (el, binding, vnode) {
		if (!binding.value || binding.value !== 'enterprise') {
			el.style.display = 'none';
		}
	},
	update: function (el, binding, vnode) {
		if (!binding.value || binding.value !== 'enterprise') {
			el.style.display = 'none';
		} else {
			el.style.display = '';
		}
	}
});

Vue.directive('large_firm', {
	bind: function (el, binding, vnode) {
		if (!binding.value || (binding.value !== 'enterprise' && binding.value !== 'large firm')) {
			el.style.display = 'none';
		}
	},
	update: function (el, binding) {
		if (!binding.value || (binding.value !== 'enterprise' && binding.value !== 'large firm')) {
			el.style.display = 'none';
		} else {
			el.style.display = '';
		}
	}
});





Vue.directive('lazyload', LazyLoadDirective);
