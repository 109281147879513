import store from "./store";
import AWSAppSyncClient from "aws-appsync";
import * as awsVars from '../../config/aws-exports';
let AWSConfig = awsVars.default(process.env);
import Auth from '@aws-amplify/auth';
import gql from "graphql-tag";
import * as Queries from '../../graphql/queries';
import * as Mutations from '../../graphql/mutations';
import Blackbox from "./blackbox";
import {toast} from "@/plugins/toast";

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore'
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all'
	}
};

let appSyncClientSingleton = new AWSAppSyncClient({
	url: AWSConfig.aws_appsync_graphqlEndpoint,
	region: AWSConfig.aws_appsync_region,
	auth: {
		type: AWSConfig.aws_appsync_authenticationType,
		jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
	},
	defaultOptions
});

export default class BaseService {
	store = store;
	appSyncClient = appSyncClientSingleton;
	blackbox = new Blackbox("DEBUG");

	_checkVuexCache(path) {
		return !!this._deepGet(path);
	}

	_deepSet(obj, path, value) {
		let schema = obj;  // a moving reference to internal objects within obj
		let pList = path.split('.');
		let len = pList.length;
		for (let i = 0; i < len - 1; i++) {
			let elem = pList[i];
			if (!schema[elem]) schema[elem] = {};
			schema = schema[elem];
		}

		schema[pList[len - 1]] = value;
	}

	_deepGetCacheExpired(path) {
		let expirationDateExpired = true;
		if (this.store.state.cache && this.store.state.cache.hasOwnProperty(path)) {
			let nowDate = new Date();
			if (this.store.state.cache[path] > nowDate) {
				expirationDateExpired = false;
			}
		}
		return expirationDateExpired;
	}

	_deepGet(path, collection) {
		let schema = typeof collection !== "undefined" ? collection : this.store.state;  // a moving reference to internal objects within obj
		let pList = path.split('.');
		let len = pList.length;
		for (let i = 0; i < len - 1; i++) {
			let elem = pList[i];
			if (!schema[elem]) schema[elem] = {};
			schema = schema[elem];
		}

		return schema[pList[len - 1]];
	}

	_appSyncCall(callType, name, parameters, path, options) {
		return new Promise((resolve, reject) => {
			if (typeof parameters === "string" && typeof path === "undefined") {
				path = parameters;
				parameters = undefined;
			}
			let self = this;

			let appSyncCachePolicy = "no-cache";
			let vuexCache = true;

			if (options) {
				if (options.hasOwnProperty("appSyncCache")) {
					appSyncCachePolicy = options.appSyncCache ? "cache-first" : "no-cache";
				}

				if (options.hasOwnProperty("vuexCache")) {
					vuexCache = !!options.vuexCache;
				}
			}

			let gqlCommand = null;
			let commandOptions = {
				fetchPolicy: appSyncCachePolicy,
				variables: parameters
			};

			let cachedResult = null;
			let haveCachedResult = false;
			if (path && callType === "query" && vuexCache && this._checkVuexCache(path)) {
				if (!this._deepGetCacheExpired(path)) {
					cachedResult = this._deepGet(path);
					haveCachedResult = true;
				}
			}

			if (haveCachedResult) {
				//this.debug(`AppSync Cached Result for: ${name} => ` + JSON.stringify(cachedResult));
				resolve(cachedResult);
			} else {
				if (callType === "query" && Queries[name]) {
					gqlCommand = Queries[name];
					commandOptions.query = gql(gqlCommand);
				} else if (callType === "mutate" && Mutations[name]) {
					gqlCommand = Mutations[name];
					commandOptions.mutation = gql(gqlCommand);
				}

				if (!gqlCommand) {
					reject("Invalid request name: " + name);
				} else {
					//Check Vuex Cache?
					//this.log("Calling AppSync: " + name);

					self.appSyncClient[callType](commandOptions).then((commandResponse) => {
						let finalResult = null;
						if (commandResponse && commandResponse.data && commandResponse.data.hasOwnProperty(name)) {
							finalResult = commandResponse.data[name];
						} else if (commandResponse && commandResponse.data) {
							finalResult = commandResponse.data;
						} else {
							finalResult = commandResponse;
						}

						//this.debug(`AppSync Result for: ${name} => ` + JSON.stringify(finalResult));

						if (path) {
							self.store.commit(path, finalResult);
							let expirationDate = new Date();
							expirationDate.setTime(expirationDate.getTime() + (60 * 60 * 1000));
							self.store.state.cache[path] = expirationDate;
							//this.debug(`AppSync Result for: ${name} committed to store => ${path}`);
						}

						resolve(finalResult);
					}).catch((err) => {
						if (err && err.graphQLErrors) {
							for (let e = 0; e < err.graphQLErrors.length; e++) {
								let gqlError = err.graphQLErrors[e];
								console.error(`GQL ${name} Error: ${gqlError.message} [${gqlError.path ? gqlError.path.join('/') : 'none'}]`);
							}
						} else {
							console.error("Unable to processes GQL request: " + JSON.stringify(err));
						}

						reject(err);
					});
				}
			}
		});
	}

	query(name, parameters, path, options) {
		return this._appSyncCall("query", name, parameters, path, options);
	}

	mutation(name, parameters, path, options) {
		return this._appSyncCall("mutate", name, parameters, path, options);
	}

	_log(message) {
		this.blackbox.writeLog(message)
	}

	_info(message) {
		this.blackbox.writeInfo(message)
	}

	_warn(message) {
		this.blackbox.writeWarn(message)
	}

	_error(message) {
		this.blackbox.writeError(message)
	}

	_fatal(message) {
		this.blackbox.writeFatal(message)
	}

	_debug(message) {
		this.blackbox.writeDebug(message)
	}

	_trace(message) {
		this.blackbox.writeTrace(message)
	}

	notification(mode, message, shy) {
		let backgroundColor = "rgba(86,124,206," + (shy ? '.5' : '1') + ")";

		if (mode === "success") {
			backgroundColor = "rgba(39,174,96," + (shy ? '.5' : '1') + ")";
		} else if (mode === "error") {
			backgroundColor = "rgba(192,57,43," + (shy ? '.5' : '1') + ")";
		}

		const options = {
			style: {
				main: {
					background: backgroundColor,
					color: "white"
				}
			},
			settings: {
				duration: shy ? 1000 : 5000
			}
		};

		toast.toast(message, options);
	}
}


