<template>
  <v-menu offset-y>
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn icon large v-on="{ ...onMenu, ...onTooltip }" class="initials-icon" style="background-color: lightgray; color: black; margin-left: 4px; margin-top: 0px;">
            <span>{{ getInitials }}</span>
          </v-btn>
        </template>

        <span>Profile Menu</span>
      </v-tooltip>
    </template>
    <v-card class="mx-auto" min-width="150" tile style="min-width: 150px">
      <v-list dense flat>
        <div @click="goToProfile()" style="cursor: pointer">
          <v-subheader
              style="height: 20px; margin-top: 10px; margin-left: 8px; font-size: .9em;">
            {{ cognito ? cognito.attributes.name : '' }}
          </v-subheader>

          <div class="truncate pr-4" style="padding-bottom: 10px; margin-bottom: 8px; border-bottom: 1px solid lightgray">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" style="font-size: .7em; margin: 0 15px;">{{ cognito ? cognito.attributes.email : '' }}</span>
                </template>
              <span>{{ cognito ? cognito.attributes.email: '' }}</span>
            </v-tooltip>

  <!--          <span style="font-size: .6em; font-style: italic; margin: 0 15px;" v-if="organization">{{ organization ? organization.tier : '' }}</span>-->
          </div>
        </div>
        <v-list-item v-for="(item, index) in allProfileItems" :key="item.text"
                     v-if="hasAccessToMenuItem(item)"
                     @click="menuAction(item, $event)"
                     class="menu-link">
          <v-list-item-icon class="mr-0">
            <v-icon small v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import {VueController} from '@/service/support/vuex-controller';

	export default VueController("BaseContainer", {
		state: [
			"cognito",
      "organization",
      "modals",
			"profileItems",
			"pageProfileItems"
		],
		methods: {

        hasAccessToMenuItem(item) {
            let hasAccess = false;
            if (!item.access || item.access === "guest") {
                hasAccess = true;
            } else if (this.cognito) {
                let userType = this.cognito.attributes["custom:mutableUserType"];
                if (item.access === "member" && (userType === "member" || userType === "admin")) {
                    hasAccess = true;
                } else if (item.access === "admin" && userType === "admin") {
                    hasAccess = true;
                }
            }

            return hasAccess;
        },
      goToProfile() {
        this.$router.push("/profile").catch(error => {
          this.error(error);
        });
      },
			menuAction(item, event) {
        if (typeof item === "string" && item === "/vaults") {
          window.location = item;
        } else if (item && item.click && typeof item.click === "function") {
					item.click(event);
        } else if (item && item.modal) {
          this.modals[item.modal] = true;
				} else if (item && item.url) {
          if (item.url === "/vaults") {
            window.location = item.url;
          } else {
            this.$router.push(item.url).catch(error => {
              this.error(error);
            });
          }
				} else if (item && item.click && typeof item.click === "string") {
					this.$router.push(item.click).catch(error => {
						this.error(error);
					});
				} else {
					this.error("Invalid menu item click. " + event ? JSON.stringify(event) : "");
				}
			}
		},
		computed: {
      getInitials(){
        let initials = [];
        if (this.cognito && this.cognito.attributes.name) {
          try {
            initials = this.cognito.attributes.name.toUpperCase().replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
            if (initials === null || initials.length === 0) {
              initials = this.cognito.attributes.name.substring(0, 1).toUpperCase();
            }
          } catch (e) {
            console.error("Unable to get initials.");
          }
        }

        return initials.join('');
      },
			allProfileItems() {
				return this.profileItems.concat(this.pageProfileItems);
			}
		}
	});
</script>

<style lang="scss" scoped>

.truncate {
  width: 200px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-break: break-word;
}

.menu-link {
  text-decoration: none;
  color: #2f3647;
}

.menu-link:hover {
  background: rgba(228, 210, 245, 0.32);
}
</style>
