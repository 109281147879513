import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import Blackbox from "./blackbox";
import {toast} from "@/plugins/toast";

let JD = {};
JD.debounce = (path, func, wait, immediate) => {
	return function() {
		let context = this,
			args = arguments;
		let later = function() {
			JD[path] = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		let callNow = immediate && !JD[path];
		clearTimeout(JD[path]);
		JD[path] = setTimeout(later, wait || 200);
		if (callNow) {
			func.apply(context, args);
		}
	};
};

function notification(mode, message, shy) {
	let backgroundColor = "rgba(86,124,206," + (shy ? '.5' : '1') + ")";

	if (mode === "success") {
		backgroundColor = "rgba(39,174,96," + (shy ? '.5' : '1') + ")";
	} else if (mode === "error") {
		backgroundColor = "rgba(192,57,43," + (shy ? '.5' : '1') + ")";
	}

	const options = {
		style: {
			main: {
				background: backgroundColor,
				color: "white"
			}
		},
		settings: {
			duration: shy ? 1000 : 5000
		}
	};

	toast.toast(message, options);
}

let VueController = function(name, controller) {
	if (!controller && typeof name !== 'string') {
		controller = name;
	} else {
		controller.name = name;
	}

	if (!controller.computed) {
		controller.computed = {};
	}

	let blackbox = new Blackbox("DEBUG");

	if (!controller.methods) {
		controller.methods = {};
	}

	controller.methods.notification = notification;

	controller.methods.log = blackbox.writeLog;
	controller.methods.info = blackbox.writeInfo;
	controller.methods.warn = blackbox.writeWarn;
	controller.methods.error = blackbox.writeError;
	controller.methods.fatal = blackbox.writeFatal;
	controller.methods.debug = blackbox.writeDebug;
	controller.methods.trace = blackbox.writeTrace;

	controller.methods.chill = function() {};
	controller.methods.dialog = function(ref, data) {
		if (typeof ref === 'string') {
			if (!this.$store.state.dialogs[ref]) {
				this.$store.state.dialogs[ref] = {};
			}
			this.$store.state.dialogs[ref].isOpen = true;
			if (data && typeof data === "string") {
				this.$store.state.dialogs[ref].data = this.$store.state[data];
			} else if (data) {
				this.$store.state.dialogs[ref].data = data;
			}
		}
	};
	controller.methods.openPopup = controller.methods.dialog;
	controller.methods.closePopup = function(ref) {
		let retData = null;
		if (typeof ref === 'string') {
			this.$store.state.dialogs[ref].isOpen = false;
			retData = this.$store.state.dialogs[ref].data;
		}
		return retData;
	};
	controller.methods.popupError = function(ref, error) {
		if (typeof ref === 'string') {
			this.$store.state.dialogs[ref].error = error;
		}
	};

	//SOMEDAY: controller.store = VuexService; should instead be an inversion of control.. load this in after the instantiation.

	//Adapt map methods from Vuex
	let mappedState = {};

	if (controller.state && Array.isArray(controller.state)) {
		for (let cs = 0; cs < controller.state.length; cs++) {
			mappedState[controller.state[cs]] = controller.state[cs];
		}
	} else if (controller.state) {
		mappedState = controller.state;
	}

	delete controller.state;

	let modelGettersSetters = {};

	for (let stateFieldName in mappedState) {
		if (!mappedState.hasOwnProperty(stateFieldName)) {
			continue;
		}

		let stateValue = mappedState[stateFieldName];
		if (stateValue && typeof stateValue === 'string' && stateValue.indexOf('/') > 0) {
			let statePath = stateValue.split('/');
			let moduleName = statePath[0];
			let moduleStatePath = statePath[1];
			mappedState[stateFieldName] = function(state) {
				return hunt(state[moduleName], moduleStatePath);
			}
		}

		if (!mappedState.hasOwnProperty(stateFieldName)) {
			continue;
		}

		let modelValue = mappedState[stateFieldName];
		let modelName = stateFieldName;
		if (Array.isArray(mappedState)) {
			modelName = modelValue;
		}

		modelGettersSetters[modelName] = {
			get() {
				return hunt(this.$store.state,
					typeof modelValue === 'string' ? modelValue : modelName);
			},
			set(value) {
				if (typeof modelValue === 'function') {
					this.$store.commit(modelName, modelValue(value));
				} else {
					this.$store.commit(modelName, value);
				}
			}
		};
	}

	if (mappedState) {
		controller.computed = {
			...controller.computed,
			...mapState(mappedState),
			...mapGetters(controller.getters ? controller.getters : {}),
			...mapMutations(controller.setters ? controller.setters : {}),
			...mapActions(controller.actions ? controller.actions : {
				ensureUserAndToken() {
					//If no token or isAuthenticated, emit 'needAuthentication'
					//console.log("Check for user and token."); //TODO: Check user and token.
				}
			}),
			...modelGettersSetters
		}
	}

	controller.methods.hasVaultAccess = function(vault, cognito) {
		let hasPermissions = false;

		if (vault && vault.private_mode === true && cognito.attributes["custom:mutableUserType"] === "admin") {
			hasPermissions = true;
		} else if (vault && vault.private_mode !== true && cognito.attributes["custom:mutableUserType"] !== "guest" && vault.org_id === cognito.attributes["custom:mutableOrgId"]) {
			hasPermissions = true;
		} else {
			for (let i = 0; i < vault.permissions.length; i++) {
				let perm = vault.permissions[i];
				if (perm.user_id && perm.user_id.toLowerCase() === cognito.attributes.sub.toLowerCase()
					&& (perm['access_vault'] === true || !perm.hasOwnProperty("access_vault") && perm['view_files'] === true)
					&& perm._deleted !== 1) {
					hasPermissions = true;
					break;
				}
			}
		}

		return hasPermissions;
	}

	controller.methods.action = function(path, data) {
		return this.$store.dispatch('ensureUserAndToken', {})
			.then(() => {
				return this.$store.dispatch(path, data);
			}).catch((response) => {
				return new Promise((resolve, reject) => {
					reject(response ? response : "Unknown error.");
				});
			});
	};

	controller.methods.url = function(path, params) {
		this.$router.push({name: path, params: params});
	};

	controller.methods.loginWithMessage = function(message) {
		window.location.href = "/login?prompt=" + encodeURI(btoa(message));
	};

	controller.methods.pureDebounce = JD.debounce;

	controller.methods.debounce = function(path, data) {
		JD.debounce(path, () => {
			this.$store.dispatch('ensureUserAndToken', {})
				.then(() => {
					return this.$store.dispatch(path, data);
				}).catch((response) => {
				this.$toastr.s("Unable to ensure user or token.");
				return new Promise((resolve, reject) => {
					reject(response);
				});
			});
		})();
	};



	controller.methods.mutate = function(path, data) {
		return this.$store.commit(path, data);
	};

	controller.methods.set = controller.methods.mutate;
	controller.methods.update = controller.methods.mutate;

	controller.methods.signIn = function(redirect, reason) {
		let nextPath = '/signin';
		let params = [];
		if (redirect && redirect !== '/signin') {
			params.push("redirect=" + redirect);
		}
		if (reason) {
			params.push("reason=" + reason);
		}
		if (params.length > 0) {
			nextPath += "?" + params.join('&');
		}

		//next({ path: nextPath });

		this.$router.push({ path: nextPath });
	};

	delete controller.getters;
	delete controller.setters;
	delete controller.actions;
	delete controller.model;

	return controller;
};

let hunt = function(obj, path) {
	let paths = path.split('.'), current = obj, i;

	for (i = 0; i < paths.length; ++i) {
		if (current[paths[i]] === undefined) {
			return undefined;
		} else {
			current = current[paths[i]];
		}
	}
	return current;
};


export {VueController};
