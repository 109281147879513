<template>
    <v-dialog v-model="dialogs[modalId].isOpen" :persistent="isPersistent" max-width="800px"> <!-- base component -->
        <v-card>
            <v-card-title class="zapa-fill py-1 px-4">
              <span>{{title}}</span>
              <v-spacer></v-spacer>
              <v-btn v-show="showDismiss"
                  icon
                  dark
                  @click="cancelInfo()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="py-1">
                <div :style="{'margin-top': dialogs[modalId].error ? '8px' : '0'}">
                    <span style="color: red; font-style: italic;" v-if="dialogs[modalId].error">{{dialogs[modalId].error}}</span>
                </div>
                <v-container class="px-0">
                    <slot v-bind:data="dialogs[modalId].data">
                        <!-- Default body contents -->
                        <p>Modal Dialog not yet implemented.</p>
                    </slot>
                </v-container>
                <small>{{hint}}</small>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="ok" v-if="!useCustomActions && showOk">
                    <v-btn :color="okColor ? okColor : '#0D7542'"
                           style="color: #FAF5E3"
                           :disabled="dialogs[modalId].inProgress" @click="saveInfo()">{{okText ? okText : 'Save'}}</v-btn>
                </slot>
                <slot name="cancel" v-if="!useCustomActions && showClose">
                    <v-btn :color="cancelColor ? cancelColor : '#0D7542'"
                           :style="{'color': cancelColor === 'default' ? 'black' : '#FAF5E3'}"
                           @click="cancelInfo()">{{cancelText ? cancelText : 'Cancel'}}</v-btn>
                </slot>
                <slot name="custom-actions" v-if="useCustomActions || showOther"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import {VueController} from "@/service/support/vuex-controller";

export default VueController("PopupDialog", {
    data() {
        return {
            modalId: Math.random()
        }
    },
    props: {
      hint: {
        type: String,
        default: ""
      },
	    title: {
			type: String,
		    default: ""
	    },
	    data: {
			type: [Object, Array]
	    },
	    okText: {
		    type: String,
		    default: "Save"
	    },
	    okColor: {
		    type: String,
		    default: "#0D7542"
	    },
	    cancelText: {
		    type: String,
		    default: "Cancel"
	    },
	    cancelColor: {
		    type: String,
		    default: "#0D7542"
	    },
      isPersistent: {
      type: Boolean,
        default: false
      },
	    showClose: {
			  type: [Boolean, Function],
		    default: true
	    },
      showDismiss: {
        type: [Boolean],
        default: false
      },
	    showOk: {
		    type: [Boolean, Function],
		    default: true
	    },
	    showOther: {
		    type: [Boolean, Function],
		    default: false
	    }
    },
    state: [
        "dialogs"
    ],
    computed: {
        useCustomActions() {
            return !!this.$slots["custom-actions"];
        }
    },
    created() {
        if (this.$vnode.data.ref) {
            this.modalId = this.$vnode.data.ref;
            Vue.set(this.dialogs, this.modalId, {
                inProgress: false,
                isOpen: false,
                error: null,
                data: {}
            });
        }
    },
    methods: {
        saveInfo() {
          if (typeof this.$listeners.savePromise === 'function') {
            this.$listeners.savePromise(this.dialogs[this.modalId].data).then(() => {
              this.inProgress = false;
              this.closeDialog();
            }).catch(error => {
              this.inProgress = false;
            });
          } else {
            try {
              if (typeof this.$listeners.savePromise === 'function') {
                this.$listeners.save(this.dialogs[this.modalId].data);
              } else {
                this.$emit('save', this.dialogs[this.modalId].data);
              }
              this.closeDialog();
              this.inProgress = false;
            } catch (e) {
              console.log("Error saving popup data: " + JSON.stringify(e));
              this.dialogs[modalId].error = e;
              this.inProgress = false;
            }
          }
        },
        cancelInfo() {
          if (typeof this.$listeners.cancelPromise === 'function') {
            this.$listeners.cancelPromise(this.dialogs[this.modalId].data).then(() => {
              this.inProgress = false;
              this.closeDialog();
            }).catch(error => {
              this.inProgress = false;
            });
          } else {
            try {
              if (typeof this.$listeners.cancelPromise === 'function') {
                this.$listeners.cancel(this.dialogs[this.modalId].data);
              } else {
                this.$emit('cancel', this.dialogs[this.modalId].data);
              }
              this.closeDialog();
              this.inProgress = false;
            } catch (e) {
              console.log("Error saving popup data: " + JSON.stringify(e));
              this.dialogs[modalId].error = e;
              this.inProgress = false;
            }
          }
        },
        closeDialog() {
            this.dialogs[this.modalId].isOpen = false;
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
