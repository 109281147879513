import {EventBus} from '@/service/support/event-bus';
import {lazyLoadImage} from '@/service/support/image-support';

export default {
	bind: el => {
		//console.log("Binding Refresh Lazy listener.");
		EventBus.$on("refreshlazy", event => {
			lazyLoadImage(el, event);
		});
	},
	inserted: el => {
		//console.log("Inserted happened.");
		lazyLoadImage(el);
		/*
		function handleIntersect(entries, observer) {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					lazyLoadImage(el);
					observer.unobserve(el);
				}
			});
		}

		function createObserver() {
			const options = {
				root: null,
				threshold: "0"
			};
			const observer = new IntersectionObserver(handleIntersect, options);
			observer.observe(el);
		}

		if (window["IntersectionObserver"]) {
			createObserver();
		} else {
			lazyLoadImage(el);
		}

		 */
	}
};
