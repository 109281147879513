import moment from "moment";

export default class Blackbox {
	_outputFunction = this._consoleWriter;
	_outputOptions = {
		timeStamp: true,
		timeStampFormat: "MM/DD/YYYY hh:mm:ss A",
		loggingLevel: "INFO",
		themes: {
			"DEBUG": "color: blue; font-style: italic;",
			"WARN": "color: orange;",
			"ERROR": "color: red;",
			"FATAL": "color: red; font-style: italic; background-color: black;padding: 2px",
			"DEFAULT": "color: black;"
		}
	};

	//SOMEDAY: Object full of max logging level and output functions for multiple simultaneous outputs.

	static Logger = new Blackbox();

	constructor(loggingLevel, customOutputFunction) {
		this._outputOptions.loggingLevel = loggingLevel ? loggingLevel : "INFO";
		if (typeof customOutputFunction === "function") {
			this._outputFunction = customOutputFunction;
		}
		Blackbox.Logger = this;
	}

	updateOptions(outputOptions) {
		Blackbox.Logger._outputOptions = outputOptions;
	}

	_consoleWriter(message, mode) {
		let cssTheme = Blackbox.Logger._outputOptions.themes.DEFAULT;
		if (Blackbox.Logger._outputOptions.themes[mode]) {
			cssTheme = Blackbox.Logger._outputOptions.themes[mode];
		}
		if (mode === "ERROR" || mode === "FATAL") {
			console.error(("%c" + message), cssTheme);
		} else {
			console.debug(("%c" + message), cssTheme);
		}
	}

	writeFatal(message) {
		if (Blackbox.Logger._translateLoggingLevel() >= 1) {
			Blackbox.Logger._outputFunction(Blackbox.Logger._messageBuilder(message, "FATAL"), "FATAL");
		}
	}

	writeError(message) {
		if (Blackbox.Logger._translateLoggingLevel() >= 2) {
			Blackbox.Logger._outputFunction(Blackbox.Logger._messageBuilder(message, "ERROR"), "ERROR");
		}
	}

	writeWarn(message) {
		if (Blackbox.Logger._translateLoggingLevel() >= 3) {
			Blackbox.Logger._outputFunction(Blackbox.Logger._messageBuilder(message, "WARN"), "WARN");
		}
	}

	writeLog(message, context) {
		if (context) {
			message = `(${context}) ${message}`;
		}
		if (Blackbox.Logger._translateLoggingLevel() >= 4) {
			Blackbox.Logger._outputFunction(Blackbox.Logger._messageBuilder(message, "INFO"), "LOG");
		}
	}

	writeInfo(message) {
		return log(message, "INFO");
	}

	writeDebug(message, context) {
		if (context) {
			message = `(${context}) ${message}`;
		}
		if (Blackbox.Logger._translateLoggingLevel() >= 5) {
			Blackbox.Logger._outputFunction(Blackbox.Logger._messageBuilder(message, "DEBUG"), "DEBUG");
		}
	}

	writeTrace(message) {
		if (Blackbox.Logger._translateLoggingLevel() >= 6) {
			Blackbox.Logger._outputFunction(Blackbox.Logger._messageBuilder(message, "TRACE"), "DEBUG");
		}
	}

	_translateLoggingLevel() {
		let loggingLevel = 0;
		switch (Blackbox.Logger._outputOptions.loggingLevel) {
			case "ALL": loggingLevel = 7; break;
			case "TRACE": loggingLevel = 6; break;
			case "DEBUG": loggingLevel = 5; break;
			case "INFO": loggingLevel = 4; break;
			case "WARN": loggingLevel = 3; break;
			case "ERROR": loggingLevel = 2; break;
			case "FATAL": loggingLevel = 1; break;
			case "OFF": loggingLevel = 0; break;
			default: loggingLevel = 4; break;
		}
		return loggingLevel;
	}

	_messageBuilder(message, mode) {
		mode = mode ? mode : "INFO";
		let outputString = `[${mode}]\t`;
		if (Blackbox.Logger._outputOptions.timeStamp) {
			outputString += Blackbox.Logger._dateFormatter() + "\t";

		}

		if (typeof message !== "string") {
			message = JSON.stringify(Blackbox.Logger._prune(message));
		}

		outputString += `${message}`;
		return outputString;
	}

	_prune = (obj, depth = 1) => {
		if (Array.isArray(obj) && obj.length > 0) {
			return (depth === 0) ? ['???'] : obj.map(e => prune(e, depth - 1))
		} else if (obj && typeof obj === 'object' && Object.keys(obj).length > 0) {
			return (depth === 0) ? {'???':''} : Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: Blackbox.Logger._prune(obj[key], depth - 1)}), {})
		} else {
			return obj
		}
	};

	_dateFormatter() {
		let format = Blackbox.Logger._outputOptions.timeStampFormat;
		return moment().format(format);
	}
}
