import Vue from "vue";
import Router from "vue-router";
import Auth from '@aws-amplify/auth';
import Base from './service/support/base-service';
import orgService from "@/service/org-service";

const baseService = new Base();
Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	linkActiveClass: 'open active',
	scrollBehavior: () => ({y: 0}),
	routes: [
		{
			path: '/',
			redirect: '/vaults'
		},
		{
			path: '/index.html',
			redirect: '/vaults'
		},
		{
			path: '.',
			redirect: '/vaults'
		},
		{
			path: '/dashboard',
			meta: {
				requiresAuth: true,
				title: 'Reports'
			},
			name: 'Reports',
			component: () => import('./views/Dashboard')
		},
		{
			path: '/profile',
			meta: {
				requiresAuth: true,
				title: "Profile Settings"
			},
			name: 'Profile',
			component: () => import('./views/Profile')
		},
		{
			path: '/settings',
			meta: {
				requiresAuth: true,
				title: "Account Settings",
				expandScreen: true
			},
			name: 'Settings',
			component: () => import('./views/Settings')
		},
		{
			path: '/new',
			meta: {
				requiresAuth: true,
				title: "Welcome to Zapa",
				fullScreen: true
			},
			name: 'WelcomeNewAccount',
			component: () => import('./views/WelcomeNewAccount')
		},
		{
			path: '/vaults',
			meta: {
				requiresAuth: true,
				title: "All Client Portals"
			},
			name: 'Client Portals',
			component: () => import('./views/Vaults')
		},
		{
			path: '/guests',
			meta: {
				requiresAuth: true,
				title: "All Portals by Guest"
			},
			name: 'All Portals by Guest',
			component: () => import('./views/VaultsByGuest')
		},
		{
			path: '/org/:orgid/vault/:vaultid/folder/:folderid',

			meta: {
				requiresAuth: true,
				title: "Portal",
				name: "Portal"
			},
			name: 'Portal',
			component: () => import('./views/Vault')
		},
		{
			path: '/welcome',
			meta: {
				requiresAuth: true,
				title: "Welcome"
			},
			name: 'Welcome',
			component: () => import('./views/Welcome')
		},
		{
			path: '/signin',
			meta: {
				requiresAuth: false,
				title: "Sign In"
			},
			name: 'SignIn',
			component: () => import('./views/SignIn')
		},
		{
			path: "*",
			meta: {
				requiresAuth: false,
				title: "Page Not Found"
			},
			name: "Page Not Found",
			component: () => import('./views/PageNotFound')
		}
	]
});

//Stack for each resolution.
//Load Cognito
//Load User Data
//Load Organization Data

function loadCognito() {
	return new Promise((resolve, reject) => {
		Auth.currentAuthenticatedUser().then(data => {
			if (data && data.signInUserSession && data.signInUserSession.isValid()) {
				baseService.store.state.cognito = data;
				baseService.store.state.isAuthenticated = true;
				woopra.identify({name:data.attributes.name,
					email: data.attributes.email,
					Company : data.attributes['custom:orgName'] ? data.attributes['custom:orgName'] : ""
				})

				resolve(data);
			} else {
				console.error("Auth result failed to affirm sign in user session.");
				reject({
					authenticationError: true,
					redirect: "/signin",
					reason: ""
				});
			}
		}).catch(error => {
			console.error("Unable to affirm user authentication: " + JSON.stringify(error));
			reject({
				authenticationError: true,
				reason: ""
			});
		});
	});
}

function loadUserData(cognito) {
	return new Promise((resolve, reject) => {
		if (cognito && cognito.attributes['custom:mutableUserType'] === "guest") {
			woopra.identify({
				name: cognito.attributes.name,
				email: cognito.attributes.email,
				userType: cognito.attributes['custom:mutableUserType']
			}).track("Load User Data");
			for (let i = 0; i < baseService.store.state.menuItems.length; i++) {
				if (baseService.store.state.menuItems[i].url === "/vaults") {
					baseService.store.state.menuItems[i].text = "My Portals";
				}
			}
		}

		if (!baseService.store.state.user) {
			//console.error("No user data found during loadUserData");
			baseService.query("getUserById", {}, "user")
				.then(data => {
					if (data) {
						baseService.store.state.user = data;
						if (data.verified_email === false) {
							baseService.mutation("setUserToEmailVerified").then(() => {
								resolve(data);
							}).catch(() => {
								resolve(data);
							});
						} else {
							resolve(data);
						}
					} else {
						throw new Error("User data not found");
					}
				}).catch(error => {
				//console.log(JSON.stringify(error));
				//Create User Data
				if (cognito.attributes['custom:mutableUserType'] === "admin") {
					baseService.mutation("createNewAccount").then(response => { //Created new admin account
						//console.log("CreateNewAccount Result: " + JSON.stringify(response));
						baseService.query("getUserById", {}, "user").then((user) => {
							baseService.store.state.user = user;

							try {
								woopra.identify({
									Company: cognito.attributes['custom:orgName'],
									OrgId : user.administratorOf
								}).track("Create New Admin Account",
									{
										Company: cognito.attributes['custom:orgName'],
										OrgId : user.administratorOf,
										email : user.email
									});
							} catch (woopraError) {
								console.error("Woopra not loaded.");
							}

							reject({
								authenticationError: false,
								redirect: "/new",
								reason: "Please confirm new account information."
							});
						});
					}).catch(createNewAccountError => {
						reject({
							authenticationError: false,
							redirect: "/new",
							reason: "Please complete new account creation."
						});
					});
				} else {
					reject("Please sign in to continue.");
				}
			});
		} else {
			resolve(baseService.store.state.user);
		}
	});
}

function hasPast(date) {
	let now = (new Date()).getTime();
	return date !== -1 && (date * 1000) < now;
}

function loadOrganizationData(user) {
	return new Promise((resolve, reject) => {
		if (baseService.store.state.cognito.attributes['custom:mutableUserType'] !== "guest") {
			baseService.query("getOrgById", {org_id: user.memberOf}, "organization")
				.then(orgResponse => {
					baseService.store.state.organization = orgResponse;
					let orgId = orgResponse.logo_s3_path ? orgResponse.logo_s3_path : "none";
					baseService.store.state.organizationId = orgId.replace(".png","");
					if (orgResponse && (!orgResponse.stripeCustomerId || orgResponse.tier === "null" || (orgResponse.tier === "canceled" && hasPast(orgResponse.subscriptionEndDate)))) {
						if(orgResponse.stripeCustomerId && orgResponse.tier ==="null"){
							baseService.query('getStripe', {
								stripeCustomerId: orgResponse.stripeCustomerId,
								stripeEdit: true
							}).then(response =>{
									baseService.query("getOrgById", {org_id: orgResponse.id}, "organization", {vuexCache: false}).then(() => {
										//console.log("GetStripe >> Refresh Organization successful");
										resolve(null);
									}).catch(orgRefreshError =>{
										console.error("Error on refreshing org : " + orgRefreshError);
										reject({
											authenticationError: false,
											redirect: "/settings?register=true",
											reason: "Please update subscription information."
										});
									});
							}).catch(error => {
								console.error("Error allVaults getStripe : " + error);
								reject({
									authenticationError: false,
									redirect: "/settings?register=true",
									reason: "Please update subscription information."
								});
							})
						} else {
							reject({
								authenticationError: false,
								redirect: "/new",
								reason: "Please complete new account creation."
							});
						}
					} else {
						resolve(orgResponse);
					}
				}).catch(orgError => {
					//console.log("ERROR: Unable to load organization. " + JSON.stringify(orgError));
					resolve(null);
				});
		} else {
			baseService.store.state.organizationId = "none";
			resolve(null);
		}
	});
}

router.afterEach((to, from) => {
	woopra.track();
});

router.beforeResolve((to, from, next) => {
	if (to.query && to.query.action) {
		try {
			gtag('event', 'purchase', {
				"affiliation": 'stripe-' + to.query.action,
				"send_to": "GTM-N23WJCH"
			});
		} catch (gtagError) {
			console.error(JSON.stringify(gtagError));
		}
	}

	//console.log("Traveling to: " + JSON.stringify(to));
	if (to.matched.some(record => record.meta.requiresAuth)) {
		//baseService.store.state.organizationId = null;
		loadCognito()
			.then(cognito => loadUserData(cognito))
			.then(user => loadOrganizationData(user))
			.then(() => {
				next();
			}).catch((error) => {
			//console.log("User is not authenticated, redirecting to Sign In page.");
			//console.log(JSON.stringify(error));
			//console.log(JSON.stringify(to));

			if (!error) {
				error = {};
			}

			if (typeof error === "string") {
				error = {
					reason: error.toString(),
					redirect: to.fullPath
				};
			}

			if (!error.redirect) {
				error.redirect = to.fullPath;
				error.reason = "";
			}

			if (to.path === '/new' && error.authenticationError === false) {
				next();
			} else {
				if (typeof error.authenticationError !== "undefined" && error.authenticationError === false) {
					if (error.redirect && error.redirect.indexOf(to.path) === 0) {
						next();
					} else {
						if (error.redirect && error.redirect.indexOf("?") > -1) {
							next({path: `${error.redirect}&reason=${error["reason"]}`});
						} else if (error.redirect) {
							next({path: `${error.redirect}?reason=${error["reason"]}`});
						} else {
							next("/signin");
						}
					}
				} else {
					let nextPath = '/signin';
					let params = [];
					if (error.redirect && error.redirect !== '/signin') {
						params.push("redirect=" + encodeURIComponent(error.redirect));
					}
					if (error.reason) {
						params.push("reason=" + error.reason);
					}
					if (params.length > 0) {
						nextPath += "?" + params.join('&');
					}
					next({path: nextPath});
				}
			}


		});
	} else {
		next();
	}
});

export default router;
