import BaseService from "./support/base-service";
import * as awsVars from '../config/aws-exports';
let AWSConfig = awsVars.default(process.env);

const stripe = Stripe(AWSConfig.stripe_public_key);

class StripeService extends BaseService {
	jumpToNewAccountCheckoutPageFromState() {
		return this.jumpToNewAccountCheckoutPage(
			this.store.state.newOrganizationTier,
			this.store.state.stripeDiscount,
			this.store.state.organization ? this.store.state.organization.stripeCustomerId : null
		);
	};

	jumpToNewAccountCheckoutPage(newOrganizationTier, stripeDiscount, stripeCustomerId) {
		let self = this;
		return new Promise((resolve, reject) => {

			self.mutation("createStripe", {newOrganizationTier:newOrganizationTier,stripeDiscount:stripeDiscount,stripeCustomerId:stripeCustomerId})
				.then(response =>{
					console.log("CREATE STRIPE RESPONSE: " + JSON.stringify(response));
					stripe.redirectToCheckout({sessionId: response})
						.then(function (result) {
							resolve(result.error ? result.error.message : null);
						});
				}).catch(error => {
					console.error("Create stripe error: " + JSON.stringify(error));
			});
		});
	};

	jumpToEditBillingPage(stripeCustomerId) {
		let self = this;
		let planCookie = this.getCookie("plan");
		this.query("getBillingRedirectUrl", {stripeCustomerId})
			.then(url => {
				if (url === "New Account") {
					self.jumpToNewAccountCheckoutPage(planCookie ? planCookie : 'professional', false,stripeCustomerId);
				} else {
					window.location.href = url;
				}
		}).catch(error => {
			self.jumpToNewAccountCheckoutPage(planCookie ? planCookie : 'professional', false,stripeCustomerId);
		});
	};

	getCookie(cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for(var i = 0; i <ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
}

let service = new StripeService();
export default service;
