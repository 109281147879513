var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"initials-icon",staticStyle:{"background-color":"lightgray","color":"black","margin-left":"4px","margin-top":"0px"},attrs:{"icon":"","large":""}},Object.assign({}, onMenu, onTooltip)),[_c('span',[_vm._v(_vm._s(_vm.getInitials))])])]}}],null,true)},[_c('span',[_vm._v("Profile Menu")])])]}}])},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"min-width":"150px"},attrs:{"min-width":"150","tile":""}},[_c('v-list',{attrs:{"dense":"","flat":""}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToProfile()}}},[_c('v-subheader',{staticStyle:{"height":"20px","margin-top":"10px","margin-left":"8px","font-size":".9em"}},[_vm._v(" "+_vm._s(_vm.cognito ? _vm.cognito.attributes.name : '')+" ")]),_c('div',{staticClass:"truncate pr-4",staticStyle:{"padding-bottom":"10px","margin-bottom":"8px","border-bottom":"1px solid lightgray"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-size":".7em","margin":"0 15px"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.cognito ? _vm.cognito.attributes.email : ''))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.cognito ? _vm.cognito.attributes.email: ''))])])],1)],1),_vm._l((_vm.allProfileItems),function(item,index){return (_vm.hasAccessToMenuItem(item))?_c('v-list-item',{key:item.text,staticClass:"menu-link",on:{"click":function($event){return _vm.menuAction(item, $event)}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1):_vm._e()})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }