/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewAccount = /* GraphQL */ `
  mutation CreateNewAccount {
    createNewAccount
  }
`;
export const inviteNewMember = /* GraphQL */ `
  mutation InviteNewMember($org_id: ID!, $email: String!, $name: String!) {
    inviteNewMember(org_id: $org_id, email: $email, name: $name)
  }
`;
export const inviteNewGuest = /* GraphQL */ `
  mutation InviteNewGuest($guestEmail: String!, $emailList: [String], $nameList: [String], $vaultId : ID!, $logo: String) {
    inviteNewGuest(guestEmail : $guestEmail, emailList : $emailList, nameList : $nameList, vaultId: $vaultId, logo: $logo)
  }
`;

export const sendFeedback = /* GraphQL */ `
  mutation SendFeedback($name: String, $email : String, $message: String, $feedbackType : String) {
    sendFeedback(name : $name, email : $email, message : $message, feedbackType: $feedbackType)
  }
`;

export const updateGuestEmail = /* GraphQL */ `
  mutation UpdateGuestEmail($subject: String!, $header : String!, $body : String!, $replyToEmail: String) {
    updateGuestEmail(subject : $subject, header: $header, body: $body, replyToEmail : $replyToEmail)
  }
`;

export const disableMember = /* GraphQL */ `
  mutation DisableMember($memberId: String!) {
    disableMember(memberId: $memberId)
  }
`;
export const fileUploaded = /* GraphQL */ `
  mutation FileUploaded(
    $id: ID!
    $filename: String!
    $extension: String
    $size: Float
    $vault_id: ID
    $parent_folder_file_id: ID
    $org_id: ID
  ) {
    fileUploaded(
      id: $id
      filename: $filename
      extension: $extension
      size: $size
      vault_id: $vault_id
      parent_folder_file_id: $parent_folder_file_id
      org_id: $org_id
    ) {
        id
	    parent_folder_file_id
	    vault_id
	    org_id
	    name
	    extension
	    size
	    created_by
	    modified_on
	    modified_by
	    modified_by_name
        modified_by_email
	    comments {
	        comment
	        user_name
	        created_date
	        created_by
	        _lastChangedAt
	      }
	    _version
	    _deleted
	    _lastChangedAt
    }
  }
`;
export const saveVaultExternalEmail = /* GraphQL */ `
  mutation SaveVaultExternalEmail($vault_id: ID!, $org_id: ID!, $email: String!) {
    saveVaultExternalEmail(vault_id: $vault_id, org_id: $org_id, email: $email)
  }
`;
export const createVault = /* GraphQL */ `
  mutation CreateVault($name: String!, $org_id: String!) {
    createVault(name: $name, org_id: $org_id) {
      id
      name
      org_id
      org_name
      initial_user_id
      external_email
      last_upload_at
      last_pdf_sign_at
      last_pdf_form_at
      last_comment_at
      last_download_at
      last_action
      users
      permissions {
        user_name
        user_email
        view_files
        upload_files
        rename_files
        delete_files
        upload_notification
        download_notification
        invitation_accepted
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVault = /* GraphQL */ `
  mutation DeleteVault($vault_id: ID!) {
    deleteVault(vault_id: $vault_id)
  }
`;
export const updatePerms = /* GraphQL */ `
  mutation UpdatePerms($memberEmail: String!, $valueToChange: String!, $vaultId: ID) {
    updatePerms(memberEmail : $memberEmail, valueToChange: $valueToChange, vaultId: $vaultId)
  }
`;
export const savePdfForm = /* GraphQL */ `
  mutation savePdfForm(
    $vault_id: ID!
    $file_id: ID!
    $fdf_data: String
    $fdf_url: String
  ) {
    savePdfForm(
      vault_id: $vault_id
      file_id: $file_id
      fdf_data: $fdf_data
      fdf_url: $fdf_url
    )
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $file_id: [ID]!
    $vault_id: ID!
    $org_id: ID!
  ) {
    deleteFile(
      file_id: $file_id
      vault_id: $vault_id
      org_id: $org_id
    )
  }
`;
export const setUserToEmailVerified = /* GraphQL */ `
  mutation SetUserToEmailVerified {
    setUserToEmailVerified
  }
`;
export const vaultInvite = /* GraphQL */ `
  mutation VaultInvite($vaultId: ID!, $acceptInvite: Boolean!) {
    vaultInvite(vaultId: $vaultId, acceptInvite: $acceptInvite)
  }
`;
export const renameFile = /* GraphQL */ `
  mutation RenameFile(
    $isFolder: Boolean!
    $file_id: ID!
    $org_id: ID!
    $vault_id: ID!
    $name: String!
  ) {
    renameFile(
      isFolder: $isFolder
      file_id: $file_id
      org_id: $org_id
      vault_id: $vault_id
      name: $name
    )
  }
`;
export const updateFileComment = /* GraphQL */ `
  mutation UpdateFileComment(
    $file_id: ID!
    $org_id: ID!
    $vault_id: ID!
    $comment: String!
    $comment_id: ID
  ) {
    updateFileComment(
      file_id: $file_id
      org_id: $org_id
      vault_id: $vault_id
      comment: $comment
      comment_id: $comment_id
    )
  }
`;
export const updateVaultSettings = /* GraphQL */ `
  mutation UpdateVaultSettings(
    $org_id: ID!
    $vault_id: ID!
    $name: String
    $private_mode: Boolean
    $hide_perms: Boolean
    $restricted_upload_path: String
  ) {
    updateVaultSettings(
      org_id: $org_id
      vault_id: $vault_id
      name: $name
      private_mode: $private_mode
      hide_perms: $hide_perms
      restricted_upload_path: $restricted_upload_path
    )
  }
`;
export const moveFile = /* GraphQL */ `
  mutation MoveFile(
    $file_id: [ID]!
    $overwrite_id: [ID]!
    $org_id: ID!
    $vault_id: ID!
    $destination: String
    $copy: Boolean
  ) {
    moveFile(
      file_id: $file_id
      overwrite_id: $overwrite_id
      org_id: $org_id
      vault_id: $vault_id
      destination: $destination
      copy: $copy
    )
  }
`;
export const updateCompanyName = /* GraphQL */ `
  mutation UpdateCompanyName(
    $company_name: String
  ) {
    updateCompanyName(
      company_name: $company_name
    )
  }
`;
export const updateOrganizationBoolean = /* GraphQL */ `
  mutation UpdateOrganizationBoolean(
    $field: String
    $value: Boolean
    $terms: String
  ) {
    updateOrganizationBoolean(
      field: $field
      value: $value
      terms: $terms
    )
  }
`;
export const setVaultAsFavorite = /* GraphQL */ `
  mutation SetVaultAsFavorite(
    $vault_id: ID
    $remove_vault: Boolean
  ) {
    setVaultAsFavorite(
      vault_id: $vault_id
      remove_vault: $remove_vault
    )
  }
`;
export const setVaultAsTermsAccepted = /* GraphQL */ `
  mutation SetVaultAsTermsAccepted(
    $vault_id: ID
    $remove_vault: Boolean
  ) {
    setVaultAsTermsAccepted(
      vault_id: $vault_id
      remove_vault: $remove_vault
    )
  }
`;
export const convertGuestAccount = /* GraphQL */ `
  mutation ConvertGuestAccount(
    $newOrgName: String
  ) {
    convertGuestAccount(
      newOrgName: $newOrgName
    )
  }
`;
export const uploadLogo = /* GraphQL */ `
  mutation UploadLogo(
    $filename: String
    $data: String
  ) {
    uploadLogo(
      filename: $filename
      data: $data
    )
  }
`;
export const newAuditNotificationAction = /* GraphQL */ `
  mutation NewAuditNotificationAction(
      $action: String
      $action_date: String
      $file_id: ID
      $filename: String
      $description: String
      $vault_id: ID
      $org_id: ID
  ) {
    newAuditNotificationAction(
      action: $action
      action_date: $action_date
      file_id: $file_id
      filename: $filename
      description: $description
      vault_id: $vault_id
      org_id: $org_id
    )
  }
`;

export const reminders = /* GraphQL */ `
  mutation Reminders(
    $taskIds: [ID]
    $vault_id: ID
    $org_id: ID
    $due_date: String
  ) {
    reminders(
      taskIds: $taskIds
      vault_id: $vault_id
      org_id: $org_id
      due_date: $due_date
    )
  }
`;
export const createStripe = /* GraphQL */ `
  mutation CreateStripe($newOrganizationTier: String, $stripeDiscount: Boolean, $stripeCustomerId : String) {
    createStripe(newOrganizationTier: $newOrganizationTier, stripeDiscount: $stripeDiscount, stripeCustomerId: $stripeCustomerId)
  }
`;
export const updateStripe = /* GraphQL */ `
  mutation UpdateStripe(
    $orgId : ID
    $subscriptionId: String
    $newTier: String
    $cancelStripe: Boolean
    $calcProration: Boolean
    $stripeCustomerId: String
    $stripeDiscount : Boolean
  ) {
    updateStripe(
      orgId: $orgId
      subscriptionId: $subscriptionId
      newTier: $newTier
      cancelStripe: $cancelStripe
      calcProration: $calcProration
      stripeCustomerId: $stripeCustomerId
      stripeDiscount : $stripeDiscount
    )
  }
`;
export const markFilesAsRead = /* GraphQL */ `
  mutation MarkFilesAsRead(
    $fileIds: [ID]
    $org_id: ID
    $vault_id: ID
  ) {
    markFilesAsRead(
      fileIds: $fileIds
        vault_id: $vault_id
        org_id: $org_id
    )
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $vault_id: ID
    $task_id: ID
    $num_tasks_closed: Int
    $num_tasks_open: Int
    $earliest_open_task_date: String
  ) {
    deleteTask(
      vault_id: $vault_id
      task_id: $task_id
      num_tasks_closed: $num_tasks_closed
      num_tasks_open: $num_tasks_open
      earliest_open_task_date: $earliest_open_task_date
    )
  }
`;

export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $id: ID
    $vault_id: ID
    $org_id: ID
    $attached_files: [ID]
    $action_type: String
    $title: String
    $description: String
    $status: String
    $due_date: String
    $send_reminder: Boolean
    $clientResponse: String
    $assigned_guest_ids: [ID]
    $assigned_guest_names: [String]
    $assigned_member_ids: [ID]
    $assigned_member_names: [String]
    $num_tasks_closed: Int
    $num_tasks_open: Int
    $earliest_open_task_date: String
  ) {
    updateTask(
      id: $id
      vault_id: $vault_id
      org_id: $org_id
      attached_files: $attached_files
      action_type: $action_type
      title: $title
      description: $description
      status: $status
      due_date: $due_date
      send_reminder: $send_reminder
      clientResponse: $clientResponse
      assigned_guest_ids: $assigned_guest_ids
      assigned_guest_names: $assigned_guest_names
      assigned_member_ids: $assigned_member_ids
      assigned_member_names: $assigned_member_names
      num_tasks_closed: $num_tasks_closed
      num_tasks_open: $num_tasks_open
      earliest_open_task_date: $earliest_open_task_date
    )
  }
`;

export const updateVaultForTier = /* GraphQL */ `
    mutation UpdateVaultForTier {
      updateVaultForTier
}
`;


  export const assignTasks = /* GraphQL */ `
  mutation AssignTasks(
    $vault_id: ID
    $task_id: ID
    $assigned_guest_ids: [ID]
    $assigned_guest_names: [String]
    $assigned_member_ids: [ID]
    $assigned_member_names: [String]
  ) {
    assignTasks(
      vault_id: $vault_id
      task_id: $task_id
      assigned_guest_ids: $assigned_guest_ids
      assigned_guest_names: $assigned_guest_names
      assigned_member_ids: $assigned_member_ids
      assigned_member_names: $assigned_member_names
    )
  }
`;

export const updateTaskComment = /* GraphQL */ `
  mutation UpdateTaskComment(
    $vault_id: ID
    $task_id: ID
    $task_comment_id: ID
    $comment: String
  ) {
    updateTaskComment(
      vault_id: $vault_id
      task_id: $task_id
      task_comment_id: $task_comment_id
      comment: $comment
    )
  }
`;

export const markTaskAsDone = /* GraphQL */ `
  mutation MarkTaskAsDone(
    $vault_id: ID
    $task_id: ID
    $status: String
    $num_tasks_closed: Int
    $num_tasks_open: Int
    $earliest_open_task_date: String
  ) {
    markTaskAsDone(
      vault_id: $vault_id
      task_id: $task_id
      status: $status
      num_tasks_closed: $num_tasks_closed
      num_tasks_open: $num_tasks_open
      earliest_open_task_date: $earliest_open_task_date
    )
  }
`;

export const addTask = /* GraphQL */ `
  mutation AddTask(
      $vault_id: ID
      $org_id: ID
      $attached_files: [ID]
      $action_type: String
      $clientResponse: String
      $title: String
      $description: String
      $status: String
      $due_date: String
      $send_reminder: Boolean
      $assigned_guest_ids: [ID]
      $assigned_guest_names: [String]
      $assigned_member_ids: [ID]
      $assigned_member_names: [String]
      $num_tasks_closed: Int
      $num_tasks_open: Int
      $earliest_open_task_date: String
  ) {
    addTask(
      vault_id: $vault_id
      org_id: $org_id
      attached_files: $attached_files
      action_type: $action_type
      clientResponse: $clientResponse
      title: $title
      description: $description
      status: $status
      due_date: $due_date
      send_reminder: $send_reminder
      assigned_guest_ids: $assigned_guest_ids
      assigned_guest_names: $assigned_guest_names
      assigned_member_ids: $assigned_member_ids
      assigned_member_names: $assigned_member_names
      num_tasks_closed: $num_tasks_closed
      num_tasks_open: $num_tasks_open
      earliest_open_task_date: $earliest_open_task_date
    )
  }
`;

export const addTaskComment = /* GraphQL */ `
  mutation AddTaskComment(
    $vault_id: ID
    $task_id: ID
    $comment_id: ID
    $comment: String
  ) {
    addTaskComment(
      vault_id: $vault_id
      task_id: $task_id
      comment_id: $comment_id
      comment: $comment
    )
  }
`;

export const deleteTaskComment = /* GraphQL */ `
  mutation DeleteTaskComment(
    $vault_id: ID
    $task_id: ID
    $comment_id: ID
  ) {
    deleteTaskComment(
      vault_id: $vault_id
      task_id: $task_id
      comment_id: $comment_id
    )
  }
`;

export const attachFileToTaskComment = /* GraphQL */ `
  mutation AttachFileToTaskComment(
    $vault_id: ID
    $task_id: ID
    $file_id: ID
  ) {
    attachFileToTaskComment(
      vault_id: $vault_id
      task_id: $task_id
      file_id: $file_id
    )
  }
`;

export const removeFileFromTaskComment = /* GraphQL */ `
  mutation RemoveFileFromTaskComment(
    $vault_id: ID
    $task_id: ID
    $file_id: ID
  ) {
    removeFileFromTaskComment(
      vault_id: $vault_id
      task_id: $task_id
      file_id: $file_id
    )
  }
`;
