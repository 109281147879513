import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from "vuetify/src/locale/en.ts";

Vuetify.config.silent = true;

Vue.use(Vuetify);

export default new Vuetify({
	//theme: { dark: true },
	icons: {
		iconfont: "fa"
	},
	lang: {
		locales: { en },
		current: 'en'
	}
});
