/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserById = /* GraphQL */ `
  query GetUserById {
    getUserById {
      id
      administratorOf
      memberOf
      vaults {
        vault_id
        org_id
        vault_name
        invitation_accepted
      }
      displayName
      email
      phone
      verified_email
      status
      favoriteVaults
      acceptedTermVaults
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getOrgById = /* GraphQL */ `
  query GetOrgById($org_id: ID!) {
    getOrgById(org_id: $org_id) {
      id
      administrator_user_id
      companyName
      tier
      stripeSubId
      stripeCustomerId
      subscriptionEndDate
      twoStepVerificationEnabled
      twoStepVerificationPreferredMethod
      twoStepVerificationEmail
      twoStepVerificationPhone
      twoStepVerificationSMS
      singleSignOnEnabled
      termsAndConditions
      termsEnforced
      passwordControlRequirements
      deviceLimitPerUser
      allowMembersToJoinOtherVaults
      enableComments
      enableFileRequests
      body
      subject
      header
      permissions {
        user_id
        user_name
        user_email
        view_files
        upload_files
        rename_files
        delete_files
        manage_perms
        create_vault
        access_vault
        invitation_accepted
        upload_notification
        download_notification
        _deleted
      }
      limitMemberSessionTimeoutLength
      logo_s3_path
      replyToEmail
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getVaultById = /* GraphQL */ `
  query GetVaultById($vault_id: ID!, $loadAuditHistory: Boolean!) {
    getVaultById(vault_id: $vault_id,loadAuditHistory: $loadAuditHistory) {
      id
      name
      org_id
      org_name
      external_email
      initial_user_id
      last_upload_at
      last_pdf_sign_at
      last_pdf_form_at
      last_comment_at
      last_download_at
      last_action
      users
      orgTier
      permissions {
        user_name
        user_email
        user_type
        user_id
        view_files
        upload_files
        rename_files
        delete_files
        access_vault
        upload_notification
        download_notification
        invitation_accepted
        _deleted
      }
      private_mode
      hide_perms
      restricted_upload_path
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getVaultAuditHistoryById = /* GraphQL */ `
  query GetVaultById($vault_id: ID!, $loadAuditHistory: Boolean!) {
    getVaultById(vault_id: $vault_id, loadAuditHistory: $loadAuditHistory) {
      id
      name
      org_id
      org_name
      initial_user_id
      external_email
      last_upload_at
      last_pdf_sign_at
      last_pdf_form_at
      last_comment_at
      last_download_at
      last_action
      orgTier
      users
      permissions {
        user_name
        user_email
        view_files
        upload_files
        rename_files
        delete_files
        access_vault
        upload_notification
        download_notification
        invitation_accepted
        _deleted
      }
      audit_history {
        action
	    file_id
	    filename
	    description
	    action_date
	    user_id
	    org_id
	    username
      }
      private_mode
      restricted_upload_path
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getVaultsByOrgId = /* GraphQL */ `
  query GetVaultsByOrgId {
    getVaultsByOrgId {
      id
      name
      org_id
      org_name
      external_email
      initial_user_id
      last_upload_at
      last_pdf_sign_at
      last_pdf_form_at
      last_comment_at
      last_download_at
      last_action
      orgTier
      users
      permissions {
        user_name
        user_email
        user_id
        user_type
        view_files
        upload_files
        rename_files
        delete_files
        access_vault
        upload_notification
        download_notification
        invitation_accepted
        _deleted
      }
      num_tasks_closed
      num_tasks_open
      earliest_open_task_date
      private_mode
      hide_perms
      restricted_upload_path
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getMembersForOrgId = /* GraphQL */ `
  query GetMembersForOrgId($org_id: ID!) {
    getMembersForOrgId(org_id: $org_id) {
      id
      administratorOf
      memberOf
      vaults {
        vault_id
	    org_id
	    vault_name
	    invitation_accepted
      }
      displayName
      email
      phone
      verified_email
      status
      favoriteVaults
	  acceptedTermVaults
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getFilesByVaultId = /* GraphQL */ `
  query GetFilesByVaultId($vault_id: ID!, $show_deleted: Boolean) {
    getFilesByVaultId(vault_id: $vault_id, show_deleted: $show_deleted) {
      id
      vault_id
      parent_folder_file_id
      org_id
      extension
      size
      name
      created_date
      created_by
      created_by_name
      modified_on
      modified_by
      modified_by_name
      modified_by_email
      downloaded_by
      downloaded_by_host
      uploaded_by_host
      comments {
        comment
        user_name
        created_date
        created_by
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getTasksByVaultId = /* GraphQL */ `
  query getTasksByVaultId(
    $vault_id: ID
  ) {
    getTasksByVaultId(vault_id: $vault_id) {
      id
      vault_id
      org_id
      created_by
      created_by_name
      attached_files
      clientResponse
      action_type
      title
      description
      status
      due_date
      send_reminder
      completed_date
      completed_by
      completed_by_name
      comments {
        id
        comment
        created_date
        created_by
        user_name
        _version
        _deleted
        _lastChangedAt
      }
      assigned_guests {
        id
        name
        org_id
      }
      assigned_members {
        id
        name
        org_id
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getVaultToken = /* GraphQL */ `
  query getVaultToken($org_id: ID!, $vault_id: ID!, $token: String) {
    getVaultToken(org_id: $org_id, vault_id: $vault_id, token: $token)
  }
`;
export const getSignedFileUrl = /* GraphQL */ `
  query GetSignedFileUrl($org_id: ID!, $vault_id: ID!, $file_id: String!, $filename: String, $extension: String, $inline: Boolean) {
    getSignedFileUrl(org_id: $org_id, vault_id: $vault_id, file_id: $file_id, filename: $filename, extension: $extension, inline: $inline)
  }
`;
export const getStripe = /* GraphQL */ `
  query GetStripe($stripeCustomerId: String!, $stripeEdit : Boolean) {
    getStripe(stripeCustomerId : $stripeCustomerId, stripeEdit : $stripeEdit)
  }
`;
export const getBillingRedirectUrl = /* GraphQL */ `
  query GetBillingRedirectUrl($stripeCustomerId: String!) {
    getBillingRedirectUrl(stripeCustomerId : $stripeCustomerId)
  }
`;


