<template>
    <v-app>
        <component :is="layout" class="stretch" style="height: calc(100% - 20px)">
            <router-view :key="$route.fullPath"></router-view>
        </component>
    </v-app>
</template>

<script>
import {VueController} from './service/support/vuex-controller';
import {EventBus} from './service/support/event-bus';

export default VueController({
    watch: {
        '$route' (to, from) {
            document.title = to.meta.title ? ("Zapa: " + to.meta.title) : 'Zapa File Portals';
        }
    },
    state: [
        "cognito",
        "isAuthenticated",
        "user",
        "organization"
    ],
    data() {
        return {
            newAccount: true
        }
    },
    beforeCreate() {
        EventBus.$on('authState', info => {
            console.log("Checking Auth State on App: " + JSON.stringify(info));
            if (info && info.status === 'needAuthentication') {
                //Some flow has thrown a request for authentication.
                this.signIn(decodeURIComponent(info.redirect), info.reason);
            } else if (info && info.status === 'signedIn') {
                //Sign in flow completed.
                this.isAuthenticated = true;

                let queryParams = [];
                if (info.plan) {
                    queryParams.push("plan=" + info.plan);
                }
                if (info.acceptVaultId) {
                    queryParams.push("accept=" + info.acceptVaultId);
                }

                if (this.$route.query.embedded) {
                	queryParams.push("embedded=" + this.$route.query.embedded);
                }

                if (info.redirect) {
                    let paramMarker = info.redirect.indexOf('?') > -1 ? "&" : "?";
                    this.$router.push(info.redirect + (queryParams.length > 0 ? (paramMarker + queryParams.join("&")) : ""));
                } else {
                    this.$router.push("/vaults" + (queryParams.length > 0 ? ("?" + queryParams.join("&")) : ""));
                }
            } else if (info && info.status === 'signedOut') {
                //Proactive logout request from the profile menu.
                this.user = null;
                this.cognito = null;
                this.organization = null;
                this.isAuthenticated = false;

                this.signIn("/vaults", "You have been successfully signed out.");
            }
        });
    },
    computed: {
        layout() {
            if (this.$route.path === '/') {
                return '';
            } else {
                return this.$route.meta.requiresAuth ? 'base-auth' : 'base-unauth';
            }
        }
    }
});
</script>

<style lang="scss">
@import 'assets/scss/style';
</style>
