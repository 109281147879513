import BaseService from "./support/base-service";

const AWS = require('aws-sdk');
let S3 = require('aws-sdk/clients/s3');
import Auth from '@aws-amplify/auth';
import * as awsVars from '../config/aws-exports';
import {getVaultToken} from "@/graphql/queries";
let AWSConfig = awsVars.default(process.env);

const extMap = require("./extmap.json");

class VaultService extends BaseService {
	getExtensionName(extension) {
		let extName = extension.toUpperCase() + " File Type";
		for (let i = 0; i < extMap.length; i++) {
			if (extMap[i] && extMap[i].ext && extMap[i].ext === extension.toLowerCase()) {
				extName = extMap[i].name;
				break;
			}
		}
		return extName;
	}

	getVaultToken(vault) {
		let self = this;

		return new Promise((resolve, reject) => {
			let vaultTokenIsValid = false;

			Auth.currentSession().then((currentSession, auError) => {
				//console.log("DUMP CURRENT_AUTHENTICATED_USER:");
				//console.log(JSON.stringify(currentSession));

				this.query("getVaultToken", {
					vault_id: vault.id,
					org_id: vault.org_id, //SEC: Get org_id from requesting user
					token: currentSession.idToken.jwtToken
				}, undefined, {vuexCache: false}).then(vaultToken => {
					if (vaultToken) {
						vaultToken = JSON.parse(vaultToken);
					}

					if (vaultToken && vaultToken.Credentials && vaultToken.Credentials.Expiration &&
						(new Date(vaultToken.Credentials.Expiration) > (new Date()))) {
						vaultTokenIsValid = true;
					}

					if (!vaultTokenIsValid) {
						reject("Portal Security Token is Invalid.");
					} else {
						resolve({token: vaultToken, awsMobile: AWSConfig});
					}
				}).catch(error => {
					console.error(error.message);
					reject(error);
				});
			});
		});
	}

	getSignedFileUrl(file, vault, inline) {
		let self = this;
		return new Promise((resolve, reject) => {
			let getFileParams = {
				"vault_id": vault.id,
				"org_id": vault.org_id,
				"file_id": file.id,
				"filename": file.name,
				"extension": file.extension ? file.extension : file.name.split('.').pop(),
				"inline": inline
			};

			self.query("getSignedFileUrl", getFileParams, undefined, undefined, {vuexCache: false})
				.then(signedUrl => {
					//console.log(JSON.stringify(signedUrl));
					resolve(signedUrl);
				}).catch(error => {
				console.error(error);
				reject(error);
			});
		});
	}

	downloadSignedFile(vault, fileShell) {
		return new Promise((resolve, reject) => {
			let self = this;
			try {
				woopra.track("File Downloaded", {vault: vault.id, OrgId: vault.org_id});
			} catch (woopraError) {
				console.error("Woopra not loaded.");
			}


			self.getSignedFileUrl(fileShell, vault).then(url => {
				try {
					window.location.assign(url);
				} catch (windowError) {
					console.error("Failed to assign window location");
				}

				let now = new Date();
				self.mutation("newAuditNotificationAction", {
					action: "Download",
					vault_id: vault.id,
					org_id: vault.org_id,
					file_id: fileShell.id,
					filename: fileShell.name,
					action_date: now.getTime().toString()
				}).then(notificationEmails => {

					if (notificationEmails !== vault.id) {
						if (notificationEmails.indexOf("|time_suppressed") > -1) {
							self.notification("info", "Notification email has been suppressed due to a prior notification being send to portal members within the last 20 minutes.", true);
						} else {
							self.notification("success", "Download Notification emailed to " + notificationEmails);
						}
					}
					resolve(url);
				}).catch(() => {
					resolve(url);
				});
			}).catch(error => {
				reject(error);
			});
		});
	}

	downloadFile(vault, fileShell, token, skipDownload) {
		let self = this;
		if (typeof skipDownload === "undefined") {
			skipDownload = false;
		}

		return new Promise((resolve, reject) => {
			if (fileShell) {
				//1) Upload file to S3, then on success update database.

				let tempCredentials = new AWS.Credentials({
					accessKeyId: token.AccessKeyId,
					secretAccessKey: token.SecretAccessKey,
					sessionToken: token.SessionToken
				});

				try {
					let s3 = new S3({
						credentials: tempCredentials,
						region: "us-east-1",
						apiVersion: "2006-03-01",
						params: {
							Bucket: AWSConfig.aws_user_files_s3_bucket
						}
					});

					let fileKey = `${vault.org_id}/${vault.id}/${fileShell.id}`;
					let now = new Date();

					s3.getObject({
						Bucket: AWSConfig.aws_user_files_s3_bucket,
						Key: fileKey
					}, function(err, data) {
						if (err) {
							//alert("Access Denied.");
							reject('Access Denied.');
						} else {
							try {
								woopra.track("File Downloaded", {OrgId: vault.org_id, vault : vault.id});
							} catch (woopraError) {
								console.error("Woopra not loaded.");
							}

							let withinZip = false;
							if (self.store && self.store.state && self.store.state.downloadProgress) {
								self.store.state.downloadProgress.num_downloaded++;
								withinZip = self.store.state.downloadProgress.num_total > 1;
							}

							try {
								let fileBuffer = new Buffer(data.Body, 'binary');
								let fileEncoded = null;
								if (fileShell.extension === "txt" && !withinZip) {
									fileEncoded = fileBuffer.toString();
								} else {
									fileEncoded = fileBuffer.toString('base64');
								}

								data.name = fileShell.name;
								data.Body = fileEncoded;

								if (skipDownload !== true) {
									let downloadLink = document.createElement("a");
									downloadLink.href = `data:${data.ContentType};base64,${fileEncoded}`;
									downloadLink.download = fileShell.name;

									downloadLink.click();
								}

								self.mutation("newAuditNotificationAction", {
									action: "Download",
									vault_id: vault.id,
									org_id: vault.org_id,
									file_id: AWSConfig.aws_user_files_s3_bucket + "/" + fileKey,
									filename: fileShell.name,
									action_date: now.getTime().toString()
								}).then(notificationEmails => {
									self.setFileDownloadedBy(fileShell);
									if (notificationEmails !== vault.id) {
										if (notificationEmails.indexOf("|time_suppressed") > -1) {
											self.notification("info", "Notification email has been suppressed due to a prior notification being send to portal members within the last 20 minutes.", true);
										} else {
											self.notification("success", "Download Notification emailed to " + notificationEmails);
										}
									}
									resolve(data);
								}).catch(() => {
									resolve(data);
								});
							} catch (localDownloadError) {
								self.error("Local download error.");
								reject("Local download error.");
							}
						}
					})
				} catch (s3Error) {
					self.fatal("Unable to load s3 instance for download: " + JSON.stringify(s3Error));
					reject("Unable to load s3 instance for download.");
				}
			} else {
				reject("No file selected.");
			}
		});
	}

	downloadFdfData(vault, token, fileId) {
		let self = this;

		return new Promise((resolve, reject) => {
			let tempCredentials = new AWS.Credentials({
				accessKeyId: token.AccessKeyId,
				secretAccessKey: token.SecretAccessKey,
				sessionToken: token.SessionToken
			});

			try {
				let s3 = new S3({
					credentials: tempCredentials,
					region: "us-east-1",
					apiVersion: "2006-03-01",
					params: {
						Bucket: AWSConfig.aws_user_files_s3_bucket
					}
				});

				let fileKey = `${vault.org_id}/${vault.id}/${fileId}.fdf`;

				s3.getObject({
					Bucket: AWSConfig.aws_user_files_s3_bucket,
					Key: fileKey
				}, function(err, data) {
					if (err) {
						reject('FDF Access Denied.');
					} else {
						let fileBuffer = new Buffer(data.Body, 'binary');
						let fileEncoded = fileBuffer.toString();

						resolve(fileEncoded);
					}
				});
			} catch (s3Error) {
				self.fatal("Unable to load s3 instance for fdf download: " + JSON.stringify(s3Error));
				reject("Unable to load s3 instance for fdf download.");
			}
		});
	}

	downloadFileVersions(vault, fileShell, token) {
		let self = this;

		return new Promise((resolve, reject) => {
			if (fileShell) {
				//1) Upload file to S3, then on success update database.

				let tempCredentials = new AWS.Credentials({
					accessKeyId: token.AccessKeyId,
					secretAccessKey: token.SecretAccessKey,
					sessionToken: token.SessionToken
				});

				try {
					let s3 = new S3({
						credentials: tempCredentials,
						region: "us-east-1",
						apiVersion: "2006-03-01",
						params: {
							Bucket: "arn:aws:s3:::z-dev-bryan-hostingbucket" //AWSConfig.aws_user_files_s3_bucket
						}
					});

					let fileKey = `${vault.org_id}/${vault.id}/${fileShell.name}`;

					s3.listObjectVersions({
						Bucket: AWSConfig.aws_user_files_s3_bucket,
						Prefix: fileKey
					}, function(err, data) {
						if (err) {
							//alert("Access Denied.");
							reject('Access Denied.');
						} else {
							//console.log(JSON.stringify(data));
						}
					})
				} catch (s3Error) {
					self.fatal("Unable to load s3 instance for file versions: " + JSON.stringify(s3Error));
					reject("Unable to load s3 instance for file versions.");
				}
			} else {
				reject("No file selected.");
			}
		});
	}


	async pdfChangeUpload(fileBlob,vault,orgId,file){
		let self = this;
		let token = await self.getVaultToken(vault);

		return new Promise((resolve, reject) => {
				if (token && token.token && token.token.Credentials) {
					token = token.token.Credentials;
				} else if (token && token.Credentials) {
					token = token.Credentials;
				}

				if (!token || !token.SessionToken) {
					throw new Error("NO TOKEN!");
				}

				let tempCredentials = new AWS.Credentials({
					accessKeyId: token.AccessKeyId,
					secretAccessKey: token.SecretAccessKey,
					sessionToken: token.SessionToken
				});

				try {
					let s3 = new S3({
						credentials: tempCredentials,
						apiVersion: "2006-03-01",
						region: "us-east-1",
						params: {
							Bucket: AWSConfig.aws_user_files_s3_bucket
						}
					});

					let filePath = `${orgId}/${vault.id}/${file.id}`;

					let uploadRequest = s3.upload({
						Bucket: AWSConfig.aws_user_files_s3_bucket,
						region: "us-east-1",
						Key: filePath,
						Body: fileBlob,
						Metadata: {
							'filename': encodeURI(file.name),
						}
					}, function(err, data) {
						if (err) {
							if (err.code !== "RequestAbortedError") {
								console.error("Access Denied: " + JSON.stringify(err));
								//alert("Access Denied.");
								reject('Access Denied.');
							}
						} else {
							//alert('Successfully Uploaded!');
							resolve(data);
						}
					})
				} catch (s3Error) {
					console.log("Unable to load s3 instance for upload: " + JSON.stringify(s3Error));
					reject("Unable to load s3 instance for upload.");
				}
		});
	}
	uploadFile(vaultId, orgId, file, fileIndex, token, existingFileId) {
		let self = this;

		return new Promise((resolve, reject) => {
			if (file && file.upload.uuid) {
				//1) Upload file to S3, then on success update database.
				if (!token || !token.SessionToken) {
					throw new Error("NO TOKEN!");
				}

				let tempCredentials = new AWS.Credentials({
					accessKeyId: token.AccessKeyId,
					secretAccessKey: token.SecretAccessKey,
					sessionToken: token.SessionToken
				});

				try {
					let s3 = new S3({
						credentials: tempCredentials,
						apiVersion: "2006-03-01",
						region: "us-east-1",
						params: {
							Bucket: AWSConfig.aws_user_files_s3_bucket
						}
					});

					let fileId = existingFileId ? existingFileId : file.upload.uuid;

					let fileExtension = file.upload.filename && file.upload.filename.indexOf('.') > -1 ? file.upload.filename.split('.').pop() : '';
					let filePath = `${orgId}/${vaultId}/${fileId}`;
					let now = new Date();

					//let fileUrl = `https:///${awsMobile.aws_user_files_s3_bucket}.s3.amazonaws.com${filePath}`;
					let uploadRequest = s3.upload({
						Bucket: AWSConfig.aws_user_files_s3_bucket,
						region: "us-east-1",
						Key: filePath,
						Body: file.entry,
						Metadata: {
							'filename': encodeURI(file.upload.filename),
						}
					}, function(err, data) {
						if (err) {
							if (err.code !== "RequestAbortedError") {
								console.error("Access Denied: " + JSON.stringify(err));
								//alert("Access Denied.");
								reject('Access Denied.');
							}
						} else {
							//alert('Successfully Uploaded!');
							let newFile = {
								id: fileId,
								filename: file.upload.filename,
								extension: fileExtension,
								size: parseFloat(file.meta ? file.meta.size : file.upload.size),
								parent_folder_file_id: file.upload.directory,
								vault_id: vaultId,
								org_id: orgId
							};

							//If file extension is .docx, call pdf maker.

							self.mutation("fileUploaded", newFile).then(newFileResponse => {
								try {
									woopra.track("File Uploaded", {vault : vaultId});
								} catch (woopraError) {
									console.error("Woopra not loaded.");
								}

								self.mutation("newAuditNotificationAction", {
									action: "Upload",
									vault_id: vaultId,
									org_id: orgId,
									file_id: AWSConfig.aws_user_files_s3_bucket + "/" + filePath,
									filename: file.upload.filename,
									action_date: now.getTime().toString()
								}).then(notificationEmails => {
									if (notificationEmails !== vaultId) {
										if (notificationEmails.indexOf("|time_suppressed") > -1) {
											self.notification("info", "Notification email has been suppressed due to a prior notification being send to portal members within the last 20 minutes.", true);
										} else {
											self.notification("success", "Upload Notification emailed to " + notificationEmails);
										}
									}
									resolve(newFileResponse);
								}).catch(() => {
									resolve(newFileResponse);
								});
							}).catch(uploadError => {
								self.error(uploadError);
								reject('Database Access Denied.');
							});
						}
					}).on('httpUploadProgress', function(progress) {
						//console.log("Length of uploadFileQueue: DO I EXISTS??!");
						//console.log(self.store.state.uploadFileQueue ? self.store.state.uploadFileQueue.length : 0);

						self.store.state.uploadFileQueue[fileIndex].upload.progress = Math.round(progress.loaded * 100 / progress.total);

					});

					self.store.state.uploadFileQueue[fileIndex].upload.request = uploadRequest;
				} catch (s3Error) {
					console.log("Unable to load s3 instance for upload: " + JSON.stringify(s3Error));
					reject("Unable to load s3 instance for upload.");
				}
			} else {
				reject("No file selected.");
			}
		});
	}
}

let service = new VaultService();
export default service;
