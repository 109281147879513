let LocalState = {
	message: "Initial Message ...",
	otherThing: "Some INitial other THing.",
	newOrganizationTier: "",
	newOrganizationName: "",
	stripeInterval: "month",
	authProfile: {
		loggedIn: false
	},
	isAuthenticated: false,
	user: null,
	organization: null,
	organizationId: null,
	members: [],
	cognito: null,
	vaults: [],
	pageIsLoading: true,
	validPromoCode: "",
	files: {},
	downloadProgress: {
		num_downloaded: 0,
		num_total: 0,
		inProgress: false
	},
	modals: {
		feedback: false,
		newportal: false,
		referral: false,
		upgrade: false
	},
	progress: {
		referral: false,
		feedback: false,
		newportal: false
	},
	dialogs: {
		example: {
			inProgress: false,
			isOpen: false,
			data: {}
		}
	},
	sidebarShow: 'responsive',
	sidebarMinimize: true,
	uploadFileQueue: [],
	currentUploadRequest: null,
	stripeDiscount: false,
	cache: {},
	drawer: null,
	embeddedFullScreen: false,
	menuItems: [
		{icon: 'mdi-folder-multiple', text: 'Dashboard', url: "/vaults"},
		{icon: 'mdi-folder-plus', text: 'New Portal', modal: "newportal", access: "member" },
		{icon: 'mdi-account-group', text: 'All Guests', url: "/guests", access: "member"},
		{icon: 'mdi-cog', text: 'Settings', url: "/settings", access: "admin"},
		{icon: 'mdi-message', text: 'Submit Feedback', modal: "feedback"},
		{icon: 'mdi-account-heart', text: 'Refer a business!', modal: "referral"},
		{icon: 'mdi-help-circle', text: 'Help', url: "https://help.zapaportal.com", newTab: true},
		{icon: 'mdi-star', text: 'Upgrade to Pro Today!', modal:"upgrade", access: "guestUpgrade"}
		/*,
		{icon: 'mdi-cellphone-link', text: 'App downloads'}

		{
			icon: 'mdi-chevron-up',
			'icon-alt': 'mdi-chevron-down',
			text: 'More',
			model: false,
			children: [
				{icon: 'fa-vial', text: 'Test', url: "/test"},
				{text: 'Import'},
				{text: 'Export'},
				{text: 'Print'},
				{text: 'Undo changes'},
				{text: 'Other contacts'},
			],
		}*/
	],
	pageMenuItems: [],
	profileItems: [

	],
	pageProfileItems: [],
	subscriptionPlanOptions: [
		{ id: 'professional', title: 'Professional', price: {monthly: "$65", yearly:"$52"}, features:['Unlimited Users', 'Up to 1,000 Client Portals', '1Tb Limit'], flex: 3 },
		{ id: 'large firm', title: 'Large Firm',  price: {monthly: "$130", yearly:"$104"}, features:['Unlimited Users', 'Up to 10,000 Client Portals', '10Tb Limit'], flex: 3 },
		{ id: 'enterprise', title: 'Enterprise',  price: {monthly: "$235", yearly:"$188"}, features:['Unlimited Users', 'Unlimited Client Portals', 'Unlimited Storage'], flex: 3 },
	]
};

let LocalMutations = {
	set(state, [variable, value]) {
		state[variable] = value
	}
};

export default {
	state: LocalState ? LocalState : {},
	mutations: LocalMutations ? LocalMutations : {}
};
