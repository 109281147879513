

export default function getVariables(env: any) : any {
	return {
		"aws_appsync_graphqlEndpoint": env.VUE_APP_aws_appsync_graphqlEndpoint,
		"aws_user_files_s3_bucket_region": env.VUE_APP_aws_user_files_s3_bucket_region,
		"aws_user_pools_id": env.VUE_APP_aws_user_pools_id,
		"aws_user_pools_web_client_id": env.VUE_APP_aws_user_pools_web_client_id,
		"aws_cognito_identity_pool_id": env.VUE_APP_aws_cognito_identity_pool_id,
		"aws_user_files_s3_bucket": env.VUE_APP_aws_user_files_s3_bucket,
		"aws_user_thumbs_s3_bucket": env.VUE_APP_aws_user_thumbs_s3_bucket,
		"aws_org_logos_s3_bucket": env.VUE_APP_aws_org_logos_s3_bucket,
		"aws_appsync_authenticationType": env.VUE_APP_aws_appsync_authenticationType,
		"aws_project_region": env.VUE_APP_aws_project_region,
		"aws_cognito_region": env.VUE_APP_aws_project_region,
		"aws_appsync_region": env.VUE_APP_aws_project_region,
		"aws_cloudfront_hosting_oai_id": env.VUE_APP_aws_cloudfront_hosting_oai_id,
		"aws_cloudfront_files_oai_id": env.VUE_APP_aws_cloudfront_files_oai_id,
		"aws_cloudfront_files_domain": env.VUE_APP_aws_cloudfront_files_domain,
		"hosting_url": env.VUE_APP_hosting_url,
		"stripe_annual_starter": env.VUE_APP_stripe_annual_starter,
		"stripe_annual_professional": env.VUE_APP_stripe_annual_professional,
		"stripe_annual_large_firm": env.VUE_APP_stripe_annual_large_firm,
		"stripe_annual_enterprise": env.VUE_APP_stripe_annual_enterprise,
		"stripe_month_starter": env.VUE_APP_stripe_month_starter,
		"stripe_month_professional": env.VUE_APP_stripe_month_professional,
		"stripe_month_large_firm": env.VUE_APP_stripe_month_large_firm,
		"stripe_month_enterprise": env.VUE_APP_stripe_month_enterprise,
		"stripe_public_key": env.VUE_APP_stripe_public_key,
		"stripe_professional_product_id": env.VUE_APP_stripe_professional_product_id,
		"stripe_large_firm_product_id": env.VUE_APP_stripe_large_firm_product_id,
		"stripe_enterprise_product_id": env.VUE_APP_stripe_enterprise_product_id
	};
};

//TODO: Generate aws-exports from cf-web-stack and overwrite js file. Deploy vue build last.
