<template>
    <v-app id="base" class="c-app">

        <v-navigation-drawer v-model="drawer"
                             ref="leftNav"
                             :clipped="true"
                             app
                             @transitionend="navUpdated()"
                             :mini-variant="true"
                             v-if="showNavigation && !embeddedFullScreen"
                             :expand-on-hover="true">
            <v-list dense>
                <template v-for="(item, index) in allMenuItems" v-if="hasAccessToMenuItem(item)">
                    <v-list-group
                        v-if="item.children"
                        :key="item.text"
                        v-model="item.model"
                        :prepend-icon="item.model ? item.icon : item['icon-alt']"
                        append-icon="">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item
                            v-for="(child, i) in item.children"
                            :key="i" link>
                            <v-list-item-action v-if="child.icon">
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else @click="menuAction(item, $event)" :key="item.text" link>
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="menu-link">
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app v-if="!embeddedFullScreen">
            <v-icon v-show="!drawer && showNavigation"
                    @click.stop="drawer = !drawer" style="cursor: pointer; margin-right: 12px;">
                fa
                fa-bars
            </v-icon>
	        <div v-if="this.organizationId" v-lazyload class="image-wrapper logo-wrapper">
		        <img class="image-item loading" :data-url="this.organizationId"
                 @click="menuAction('/vaults')"
		             :data-source="'public_login_panel_logo'"
		             @error="$event.target.src = '/img/Zapa-Client-Portals-170.png'"
		             alt="Organization Logo"
		             :data-bucket="this.logoBucket"/>
		        <img alt="Menu" @click="menuAction('/vaults')"
		             src="../assets/images/Zapa-Client-Portals-170.png"
		             class="image-item image-fallback"/>
	        </div>
	        <div class="image-wrapper logo-wrapper" v-else>
		        <img alt="Menu" @click="menuAction('/vaults')"
		             src="../assets/images/Zapa-Client-Portals-170.png"
		             class="image-item image-fallback"/>
	        </div>

            <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-autocomplete flat solo-inverted hide-details hide-no-data
                            v-if="showNavigation"
                            v-model="select"
                            v-member
                            :loading="loading"
                            :items="items"
                            item-text="name"
                            item-value="id"
                            @change="selectVault()"
                            :clearable="true"
                            :search-input.sync="search"
                            prepend-inner-icon="mdi-magnify"
                            class="hidden-sm-and-down"
                            style="margin-right: 6px"
                            label="Search all portals">
                <template v-slot:item="data">
                    {{ data.item.id.indexOf("new_") === 0 ? "Press enter to create new portal" : data.item.name }} {{ !hasVaultAccess(data.item, cognito) ? ' (Private)' : ''}}
                </template>
            </v-autocomplete>
            <v-menu class=".notification-menu" :offset-y="true"
                    v-show="vaultsNotDeletedAndPendingInvitation && vaultsNotDeletedAndPendingInvitation.length > 0"
                    :disabled="!vaultsNotDeletedAndPendingInvitation || vaultsNotDeletedAndPendingInvitation.length === 0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="ml-1"
                           :color="!vaultsNotDeletedAndPendingInvitation || vaultsNotDeletedAndPendingInvitation.length === 0 ? '#e3e3e3' : 'primary'"
                           dark
                           v-show="vaultsNotDeletedAndPendingInvitation && vaultsNotDeletedAndPendingInvitation.length > 0"
                           v-bind="attrs"
                           v-on="on">
                        <v-badge dot color="green"
                                 :value="vaultsNotDeletedAndPendingInvitation.length"
                                 :content="vaultsNotDeletedAndPendingInvitation.length">
                            <v-icon>mdi-bell</v-icon>
                        </v-badge>
                    </v-btn>
                </template>

                <v-list>
                    <v-card class="ml-2 mr-2"
                            v-for="(item, index) in vaultsNotDeletedAndPendingInvitation"
                            :key="index">
                        <v-card-text>{{ item.org_name }} has invited you to a vault.</v-card-text>
                    </v-card>
                </v-list>
            </v-menu>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="openKnowledgebase()" v-bind="attrs" v-on="on">
                <v-icon color="primary" large style="opacity: .8; font-size: 30px">fa fa-question-circle</v-icon>
              </v-btn>
            </template>
            <span>Support</span>
          </v-tooltip>


          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="$router.push('/settings')" v-admin v-bind="attrs" v-on="on">
                <v-icon color="default" large style="opacity: .8; font-size: 30px">fas fa-cog</v-icon>
              </v-btn>
            </template>
            <span>Settings</span>
          </v-tooltip>

          <profile-menu></profile-menu>

        </v-app-bar>

        <v-dialog v-model="modals.newportal" max-width="600px"> <!--spinner done-->
            <v-card>
                <v-card-title class="headline zapa-fill">Create New Portal</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-expand-transition>
                            <v-row v-if="newPortal.error">
                                <span style="color: darkred;">{{ newPortal.error }}</span>
                            </v-row>
                            <v-row v-if="!addVaultToggle && newPortal.name">
                                <span style="color: darkred;">Please enter a unique Portal Name</span>
                            </v-row>
                        </v-expand-transition>
                        <v-row>
                            <v-col class="pa-0">
                                <v-text-field label="New Portal Name *" v-model="newPortal.name" v-on:keyup.enter="createNewPortal(newPortal)"></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- Hide optional first guest details.
                        <v-row>
                            <v-card class="fill pa-4">
                                <v-card-title class="pa-0">
                                    <h6>Optional: Grant Initial Guest Access</h6>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col class="pa-0">
                                            <v-text-field
                                                v-if="newPortal.portalNameIsUsername"
                                                label="Guest Name" v-model="newPortal.name"
                                                disabled
                                                v-on:keyup.enter="createNewPortal(newPortal)"></v-text-field>
                                            <v-text-field v-else label="Guest Name" v-model="newPortal.username" v-on:keyup.enter="createNewPortal(newPortal)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pa-0">
                                            <v-text-field :label="newPortal.portalNameIsUsername || newPortal.username.length > 0 ? 'Guest Email *' : 'Guest Email'" v-model="newPortal.email" v-on:keyup.enter="createNewPortal(newPortal)"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mb-2">
                                        <span>Enter a Guest Name and Email Address to instantly invite them by email.</span>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-row>
                        -->
                    </v-container>
                    <small>* indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="closeNewPortal(newPortal)">
                        Cancel
                    </v-btn>
                    <v-btn color="primary darken-1" text :disabled="newPortal.inProgress || !addVaultToggle" @click="createNewPortal(newPortal)">
                        Create
                        <v-icon x-small v-if="newPortal.inProgress" class="fa-spin ml-2">fas fa-sync</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modals.upgrade" max-width="800px">
            <v-card>
                <v-card-title class="headline zapa-fill">Upgrade Account</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <h3>Upgrade your guest account to a Professional plan and start creating new portals
                                today!</h3>
                        </v-row>
                        <v-row class="mt-5">
                            <span>Enter the name of your business and select a plan:</span>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-0 mt-3">
                                <v-text-field outlined label="Business Name *" v-model="newOrganizationName" v-on:keyup.enter="upgradeAccount()"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <plan-selection></plan-selection>
                        </v-row>

                    </v-container>
                    <small>* indicates required field</small>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!newOrganizationName" color="#5764a9" style="color: #FAF5E3;" @click="upgradeAccount()">
                        Continue to Payment
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- *** REFERRAL MODAL *** -->
        <v-dialog v-model="modals.referral" max-width="600px">
            <v-card>
                <v-card-title class="headline zapa-fill">Refer & Earn $50</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="my-2 ml-1 pr-1">
                            <h2 style="line-height: 1.4em;">Invite your colleagues and earn $50 off your next month fee as well as $50 off of their first month when they sign up.</h2>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Recipient Name *" variant="outlined" v-model="referral.name"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Email Address *" variant="outlined" v-model="referral.email"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>* indicates required field</small>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="closeReferral(referral)">Cancel</v-btn>
                    <v-btn color="primary darken-1"
                           :disabled="progress.referral"
                           text @click="sendReferral(referral)"
                    >Send
                        <v-icon x-small v-if="progress.feedback" class="fa-spin ml-2">fas fa-sync</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- *** FEEDBACK MODAL *** -->
        <v-dialog v-model="modals.feedback" max-width="600px">
            <v-card>
                <v-card-title class="headline zapa-fill">Feedback Form</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="my-2 ml-1">
                            <div>This form is to send feedback to us here at Zapa Client Portals</div>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    :items="['Issue', 'Feature Request', 'Feedback']"
                                    v-model="feedback.feedbackType"
                                    label="Feedback Type"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea label="Message" v-model="feedback.message"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>* indicates required field</small>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="closeFeedback(feedback)">Cancel</v-btn>
                    <v-btn color="primary darken-1"
                           :disabled="progress.feedback"
                           text @click="sendFeedback(feedback)"
                    >Send
                        <v-icon x-small v-if="progress.feedback" class="fa-spin ml-2">fas fa-sync</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackBar" :timeout=3800 :color="snackBarType">
            <span v-if="snackBarType==='error'"><i class="fal fa-exclamation-circle mr-2"></i></span>
            <span v-if="snackBarType==='success'"><i class="fal fa-thumbs-up mr-2"></i></span>{{
                snackBarNotify ? snackBarNotify : ''
            }}
        </v-snackbar>
	    <v-main :style="{ 'padding': mainPaddingStyle }">
            <v-container fluid style="padding: 10px 0 0 0; height: calc(100% - 10px)">
                <transition name="fade">
                    <slot/>
                </transition>
            </v-container>
        </v-main>

        <v-footer inset app>
            <v-row v-if="!organizationId" style="direction: rtl; padding: 10px 4px;">
                <span>Breichbilt</span>
            </v-row>
            <v-row v-else style="direction: rtl; padding: 10px 4px;">
                <v-img alt="Menu" @click="menuAction('/vaults')"
                       src="../assets/images/Zapa-Client-Portals-170.png"
                       style="max-width: 60px; cursor: pointer; margin: 0;"/>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
import {VueController} from '@/service/support/vuex-controller';
import ProfileMenu from "./ProfileMenu";
import vaultService from '../service/vault-service';
import stripeService from '../service/stripe-service'
import * as awsVars from '../config/aws-exports';
import Auth from '@aws-amplify/auth';
import PlanSelection from "@/components/PlanSelection";

let awsmobile = awsVars.default(process.env);

export default VueController("BaseContainer", {
    components: {
        PlanSelection,
        ProfileMenu
    },
    data() {
        return {
            loading: false,
            pageLoading: true,
            items: [],
            search: null,
            select: null,
	        navWideMode: false,
            referral: {
                name: "",
                email:""
            },
            feedback: {
                feedbackType: "Issue",
                message: ""
            },
            newPortal: {
                username: "",
                email: "",
                name: "",
                portalNameIsUsername: false,
                inProgress: false,
                error: ""
            },
            snackBar: false,
            snackBarType: "",
            snackBarNotify: "",
            loginOrgId: "",
            logoBucket: null
        }
    },
    state: [
        "cognito",
        "drawer",
        "user",
        "organization",
        "newOrganizationName",
        "newOrganizationTier",
        "stripeDiscount",
        "organizationId",
        "vaults",
        "menuItems",
        "profileItems",
        "pageMenuItems",
        "pageProfileItems",
        "modals",
        "progress",
	    "embeddedFullScreen"
    ],
    watch: {
        search(val) {
            val && val !== this.select && this.queryVaults(val)
        }
    },

	created() {
		this.logoBucket = awsmobile.aws_org_logos_s3_bucket;
	},
    mounted() {
        this.logoBucket = awsmobile.aws_org_logos_s3_bucket;

        if (this.$route.query.org) {
            this.loginOrgId = this.$route.query.org;
        }

        //console.log(`Left nav exists? ${!!this.$refs.leftNav}; Value of ${this.drawer}, mobile? ${this.$refs.leftNav.isMobile}, mini? ${this.$refs.leftNav.miniVariant}`);
	    //this.navWideMode = (this.$refs.leftNav && ((!this.$refs.leftNav.miniVariant && (!this.$refs.leftNav.isMobile || this.drawer === true)) || ((this.$refs.leftNav.miniVariant && this.$refs.leftNav.isMouseover) || (this.$refs.leftNav.isMobile && this.drawer === true))));
	    //console.log(this.navWideMode);
        this.pageLoading = false;
    },
    methods: {
      openKnowledgebase() {
        window.open(`https://help.zapaclientportal.com`, '_blank');
      },
    	navUpdated() {
		    //this.navWideMode = (this.$refs.leftNav && ((!this.$refs.leftNav.miniVariant && (!this.$refs.leftNav.isMobile || this.drawer === true)) || ((this.$refs.leftNav.miniVariant && this.$refs.leftNav.isMouseover) || (this.$refs.leftNav.isMobile && this.drawer === true))));
	    },
        hasAccessToMenuItem(item) {
            let hasAccess = false;
            if (!item.access || item.access === "guest") {
                hasAccess = true;
            } else if (this.cognito) {
                let userType = this.cognito.attributes["custom:mutableUserType"];
                if (item.access === "member" && (userType === "member" || userType === "admin")) {
                    if (item.access === "member" && this.user && this.organization &&
                        !!this.organization.permissions.find(perm => perm['create_vault'] === true && perm.user_id === this.user.id)) {
                        hasAccess = true;
                    } else if (item.access === "admin") {
                        hasAccess = true;
                    }
                } else if (item.access === "admin" && userType === "admin") {
                    hasAccess = true;
                } else if (item.access === "guestUpgrade" && userType === 'guest') {
                    hasAccess = true;
                }
            }
            return hasAccess;
        },
        upgradeAccount() {
            let self = this;

            if (!this.newOrganizationTier) {
                this.newOrganizationTier = 'professional';
            }

            stripeService.mutation("convertGuestAccount", {newOrgName: self.newOrganizationName}).then(response => {
                self.loadCognito().then(data => {
                    stripeService.jumpToNewAccountCheckoutPage(self.newOrganizationTier, self.stripeDiscount, null);
                })
            });
        },
        loadCognito() {
            return new Promise((resolve, reject) => {
                Auth.currentAuthenticatedUser({bypassCache: true}).then(data => {
                    if (data && data.signInUserSession && data.signInUserSession.isValid()) {
                        stripeService.store.state.cognito = data;
                        stripeService.store.state.isAuthenticated = true;
                        resolve(data);
                    } else {
                        console.error("Auth result failed to affirm sign in user session.");
                        reject({
                            authenticationError: true,
                            redirect: "/signin",
                            reason: ""
                        });
                    }
                }).catch(error => {
                    console.error("Unable to affirm user authentication: " + JSON.stringify(error));
                    reject({
                        authenticationError: true,
                        redirect: "/signin",
                        reason: ""
                    });
                });
            });
        },
        closeNewPortal(portalDetails) {
            let self = this;
            self.newPortal.username = "";
            self.newPortal.email = "";
            self.newPortal.name = "";
            self.newPortal.portalNameIsUsername = false;
            self.newPortal.error = "";
            self.newPortal.inProgress = false;
            self.modals.newportal = false;
        },
        createNewPortal(portalDetails) {
            let self = this;
            self.newPortal.inProgress = true;
            //console.log(JSON.stringify(portalDetails));

            if ((portalDetails.portalNameIsUsername !== true && portalDetails.name)
                || (portalDetails.portalNameIsUsername === true && portalDetails.username)) {
                let finalPortalName = portalDetails.name;
                vaultService.mutation("createVault", {
                    name: finalPortalName,
                    org_id: this.user.memberOf
                }).then(newVault => {
                    self.vaults.push(newVault);

                    try {
                        woopra.track("Portal Created", {OrgId: newVault.org_id});
                    } catch (woopraError) {
                        console.error("Woopra not loaded.");
                    }

                    if (portalDetails.email) {

                        let orgLogo = this.organization && this.organization.logo_s3_path ? this.organization.logo_s3_path : this.organizationId;
                        vaultService.mutation("inviteNewGuest",
                            {
                                vaultId: newVault.id,
                                logo: orgLogo,
                                guestEmail: `<${portalDetails.portalNameIsUsername ? portalDetails.name : portalDetails.username}> ${portalDetails.email}`
                            }).then(() => {
                            //Goto new vault.
                            self.newPortal.username = "";
                            self.newPortal.email = "";
                            self.newPortal.name = "";
                            self.newPortal.portalNameIsUsername = false;
                            self.newPortal.error = "";
                            self.newPortal.inProgress = false;
                            self.modals.newportal = false;

	                        let now = new Date();
	                        vaultService.mutation("newAuditNotificationAction", {
		                        action: "Invite Guest",
		                        vault_id: newVault.id,
		                        org_id: this.organization.id ? this.organization.id : this.organizationId,
		                        description: `Invite guest <${portalDetails.portalNameIsUsername ? portalDetails.name : portalDetails.username}> ${portalDetails.email}`,
		                        action_date: now.getTime().toString()
	                        });

                            try {
                                woopra.track("New Guest Invited", {OrgId : newVault.org_id, Vault : newVault.id});
                            } catch (woopraError) {
                                console.error("Woopra not loaded.");
                            }

                            //{name: 'Portal', params: {vaultid: newVault.id, orgid: newVault.org_id}}
                            self.$router.push(`/org/${newVault.org_id}/vault/${newVault.id}/folder/main`);
                        }).catch(() => {
                            self.newPortal.error = "Unable to invite new guest.";
                            self.newPortal.inProgress = false;
                            console.error("Unable to invite new guest.");
                        });
                    } else {
                        //Goto new vault
                        self.newPortal.username = "";
                        self.newPortal.email = "";
                        self.newPortal.name = "";
                        self.newPortal.portalNameIsUsername = false;
                        self.newPortal.error = "";
                        self.newPortal.inProgress = false;
                        self.modals.newportal = false;
                        self.$router.push(`/org/${newVault.org_id}/vault/${newVault.id}/folder/main`);
                    }
                }).catch(err => {
                    self.newPortal.error = "Error creating new Client Portal.";
                    self.newPortal.inProgress = false;
                });
            } else {
                self.newPortal.error = "Please enter a name for the new Client Portal.";
                self.newPortal.inProgress = false;
            }

        },
        closeFeedback(feedback) {
            let self = this;
            self.feedback.feedbackType = "Issue";
            self.feedback.message = "";
            self.modals.feedback = false;
            self.progress.feedback = false;
        },
        closeReferral(referral) {
            let self = this;
            self.referral.name = "";
            self.referral.email = "";
            self.modals.referral = false;
            self.progress.referral = false;
        },
        sendReferral(referral) {
          let self = this;
          self.progress.referral = true;
          vaultService.mutation("sendFeedback", {
              name : referral.name,
              email : referral.email
          }).then(() =>{
              self.snackBarType = "success";
              self.snackBarNotify = "Referral sent! We will let you know when your credit gets applied.";
              self.snackBar = true;
              self.referral.name = "";
              self.referral.email = "";
              self.modals.referral = false;
              self.progress.referral = false;
          })
        },
        sendFeedback(feedback) {
            let self = this;
            self.progress.feedback = true;
            vaultService.mutation("sendFeedback", {
                message: self.feedback.message,
                feedbackType: self.feedback.feedbackType
            }).then(() => {
                self.snackBarType = "success";
                self.snackBarNotify = "Thank you for your feedback.";
                self.snackBar = true;
                self.feedback.feedbackType = "Issue";
                self.feedback.message = "";
                self.modals.feedback = false;
                self.progress.feedback = false;
            }).catch(() => {
                self.snackBarType = "error";
                self.snackBarNotify = "Unable to send feedback, please email support@zapaportal.com for assistance.";
                self.snackBar = true;
                self.feedback.feedbackType = "Issue";
                self.feedback.message = "";
                self.modals.feedback = false;
                self.progress.feedback = false;
            });
        },
        selectVault() {
            if (this.select.indexOf("new_") === 0) {
                this.newPortal.username = "";
                this.newPortal.name = this.items[0].name;
                this.items = [];
                this.select = "";
                this.search = "";
                this.modals.newportal = true;
            } else {
                let selectedVault = this.vaults.find(vault => vault.id === this.select);

                if (selectedVault) {
                  if (!this.hasVaultAccess(selectedVault, this.cognito)) {
                    this.notification("error", "Selected Portal is private, please contact your administrator for access.");
                  } else {
                    this.$nextTick(() => {
                      this.select = null;
                      this.search = "";
                    });

                    this.$router.push(`/org/${selectedVault.org_id}/vault/${selectedVault.id}/folder/main`);
                  }
                }
            }
        },
        queryVaults(v) {
            let self = this;
            this.loading = true

            if (!this.vaults || this.vaults.length === 0) {
                let self = this;
                vaultService.query("getVaultsByOrgId", undefined, "vaults", {vuexCache: true}).then(response => {
                    //DONE: If 1 vault, set org id
                    if (response && response.length === 1) {
                        //self.organizationId = response[0].org_id;
                        self.pageLoading = false;
                    }

                    self.items = self.vaults.filter(e => {
                        return (e.name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1 && e._deleted !== 1;
                    });
                    if (v && (!self.items || self.items.length === 0)) {
                        if ((this.cognito.attributes["custom:mutableUserType"] === "member" && this.organization
                            && !!this.organization.permissions.find(perm => perm['create_vault'] === true && perm.user_id === this.user.id))
                            || this.cognito.attributes["custom:mutableUserType"] === "admin") {
                            self.items.push({
                                name: v,
                                id: "new_" + v,
                                '_deleted': 0
                            });
                        }
                    }
                    self.loading = false;
                }).catch(() => {
                    console.error("Error: Unable to load search results.");
                });
            } else {
                self.items = self.vaults.filter(e => {
                    return (e.name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1 && e._deleted !== 1;
                });

                if (v && (!self.items || self.items.length === 0)) {
                    if ((this.cognito.attributes["custom:mutableUserType"] === "member" && this.organization
                        && !!this.organization.permissions.find(perm => perm['create_vault'] === true && perm.user_id === this.user.id))
                        || this.cognito.attributes["custom:mutableUserType"] === "admin") {
                        self.items.push({
                            name: v,
                            id: "new_" + v,
                            '_deleted': 0
                        });
                    }
                }
                self.loading = false;
            }
        },
        menuAction(item, event) {
          let self = this;

          if (typeof item === "string") {
            if (item === "/vaults") {
              window.location = item;
            } else {
              this.$router.push(item).catch(error => {
                this.error(error);
              });
            }
          } else if (item && item.modal) {
              self.modals[item.modal] = true;
          } else if (item && item.click && typeof item.click === "function") {
              item.click(event);
          } else if (item && item.url) {
              if (item.url.indexOf("://") > -1) {
                  if (item.newTab === true) {
                      window.open(item.url, '_blank');
                  } else {
                      window.location.href = item.url;
                  }
              } else {
                if (item.url === "/vaults") {
                  window.location = item.url;
                } else {
                  this.$router.push(item.url).catch(error => {
                    if (error.type !== 4) {
                      this.error("Unable to push url: " + item.url + "; " + error.message);
                    }
                  });
                }
              }
          } else if (item && item.click && typeof item.click === "string") {
              this.$router.push(item.click).catch(error => {
                  this.error(JSON.stringify(error));
              });
          } else {
              this.error("Invalid menu item click.");
          }
        }
    },
    computed: {
    	mainPaddingStyle() {
    		let paddingStyle = '70px 0 0 53px';

        if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md') {
          paddingStyle = '64px 0 0 0';
        }
        /*
    		if (!this.navWideMode) {
    			if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md') {
            paddingStyle = '60px 0px 0px 0px';

			    } else {
    				paddingStyle = '60px 0px 0px 50px';
			    }
		    }*/

    		return paddingStyle;
	    },
        showNavigation() {
            return this.$route.meta.fullScreen !== true;
        },
        showMiniNavigation() {
            return this.$route.meta.expandScreen === true;
        },
        vaultsNotDeletedAndPendingInvitation() {
            if (this.vaults && this.cognito) {
                let userId = this.cognito.attributes.sub;
                let userType = this.cognito.attributes["custom:mutableUserType"];
                return this.vaults.filter(vault =>
                    vault._deleted !== 1 && vault.permissions
                    && vault.permissions.some(perm =>
                    userType === "guest" && (perm.user_id === userId && perm.invitation_accepted !== "true")
                    ));
            } else {
                return [];
            }
        },
        addVaultToggle() {
            if (this.existingVault.length < 1 && this.newPortal.name) {
                return true;
            } else {
                return false;
            }
        },
        existingVault() {
            let newVaultName = this.newPortal.name.toLowerCase();
            if (this.vaults && this.vaults.length > 0) {
                return this.vaults.filter(function(vault) {
                    return vault.name.toLowerCase() === newVaultName;
                })
            } else {
                return [];
            }
        },
        vaultCeiling() {
            if (this.organization) {
                if (this.organization.tier.toLowerCase() !== 'enterprise') {
                    return this.vaults.length >= this.vaultMax;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        filterDeletedVaults() {
            if (this.vaults) {
                return this.vaults.filter(vault => vault._deleted !== 1);
            }
        },
        allMenuItems() {
            return this.menuItems.concat(this.pageMenuItems);
        },
        allProfileItems() {
            return this.profileItems.concat(this.pageProfileItems);
        },
        name() {
            return this.$route.name;
        },
        list() {
            return this.$route.matched.filter((route) => route.name || route.meta.label);
        }
    }
});
</script>

<style lang="scss" scoped>
.menu-link {
    text-decoration: none;
    color: #2f3647;
}

div.v-input--checkbox label.v-label.theme--light {
    font-size: 14px;
}

.notification-menu .v-menu__content {
    margin-top: 40px;
}

.logo-wrapper {
    float: right;
    min-height: 50px;
    width: 250px;
    padding: 10px;
    margin: auto 0;
    display: flex;
    object-fit: scale-down;
    height: 100%;
    vertical-align: middle;
}

.logo-wrapper .image-item {
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
    vertical-align: middle;
    object-fit: scale-down;
    margin: auto 0;
}

.v-list-item__action {
    margin-right: 16px !important;
}

.v-list-item__title.menu-link {
    font-size: .9em !important;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: white;
}
</style>
