import Vue from "vue";
import Vuex from "vuex";
import localState from "../local-state";

//IDEA: Add local storage caching layer to local storage.

Vue.use(Vuex);

let store = {
	state: localState.state,
	mutations: localState.mutations,
	getters: {},
	actions: {}
};

for (let fieldName in store.state) {
	if (store.state.hasOwnProperty(fieldName)) {
		let localFieldName = fieldName;
		store.mutations[localFieldName] = function(state, value) {
			state[localFieldName] = value;
		};

		store.getters[localFieldName] = function(state) {
			return state[localFieldName];
		}
	}
}

store.update = function(fieldName, value) {
	store.mutations[fieldName](store.state, value);
};
store.set = store.update;

store.get = function(fieldName) {
	return huntValue(store.state, fieldName);
};

let huntValue = (obj, path) => {
	let paths = path.split('.'), current = obj, i;

	for (i = 0; i < paths.length; ++i) {
		if (current[paths[i]] === undefined) {
			return undefined;
		} else {
			current = current[paths[i]];
		}
	}
	return current;
};

export default new Vuex.Store(store);
