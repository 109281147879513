import BaseService from "./support/base-service";

class OrganizationService extends BaseService {
	updateOrganizationSetting(organizationId, organizationSettings) {
		let settings = organizationSettings;
		settings[id] = organizationId;

		return this.mutation("updateOrganization", {input: settings});
	}
}

let service = new OrganizationService();
export default service;
