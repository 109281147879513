import Auth from '@aws-amplify/auth';
const AWS = require('aws-sdk');
let S3 = require('aws-sdk/clients/s3');

export function lazyLoadImage(el, refreshEvent) {
	const elImage = Array.from(el.children).find(
		elSub => elSub.nodeName === "IMG"
	);

	const elName = Array.from(el.children).find(
		elSub => elSub.className.indexOf("image-fallback") > -1
	);

	let s3_path = refreshEvent ? refreshEvent.newId : null;

	if ((refreshEvent && !elImage.dataset.url && !refreshEvent.oldId)
		|| (refreshEvent && elImage.dataset.url && elImage.dataset.url !== "none" && refreshEvent.oldId
			&& refreshEvent.oldId.replace(".png", "") !== elImage.dataset.url.replace(".png", ""))
		|| elImage.dataset.retried === "true") {
		//console.log(`DUMP: ${JSON.stringify(refreshEvent)} - URL: ${elImage.dataset.url}`);
		//console.log("REFRESH DOES NOT APPLY TO " + elImage.dataset.source);
	} else {
		if (elImage) {
			elImage.classList.remove("no-logo-url");
		}
		if (elName) {
			elName.classList.remove("fallback-active");
		}

		//console.log("Lazy Image from " + elImage.dataset.source);

		let finalUrl = null;
		if (s3_path && s3_path !== 'none' && s3_path !== 'zapa') {
			finalUrl = s3_path;
		} else if (!s3_path && elImage && elImage.dataset && elImage.dataset.url && elImage.dataset.url !== 'none' && elImage.dataset.url !== '-1') {
			finalUrl = elImage.dataset.url;
		}

		if (!finalUrl) {
			if (elImage) {
				elImage.classList.add("no-logo-url");
			}
			if (elName) {
				elName.classList.add("fallback-active");
			} else {
				//console.log("No image fallback.");
			}
		} else {
			//TODO: CHECK IF finalUrl is UUID, if so add .png
			if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(finalUrl)) {
				finalUrl += ".png";
			}

			if (elImage) {
				let tempCredentials = null;

				if (elImage.dataset.token) {
					let token = JSON.parse(elImage.dataset.token).Credentials;

					tempCredentials = new AWS.Credentials({
						accessKeyId: token.AccessKeyId,
						secretAccessKey: token.SecretAccessKey,
						sessionToken: token.SessionToken
					});
				}

				if (elImage.dataset.source && elImage.dataset.source.indexOf("public_") === 0) {
					//download image without credentials
					//console.log("Downloading public lazy image.");
					//console.log("Bucket: " + JSON.stringify(elImage.dataset));

					let now = new Date();
					elImage.src = `https://s3.amazonaws.com/${elImage.dataset.bucket}/${finalUrl}?ts=${now.getTime()}`;
					elImage.onerror="this.onerror=null;"
					elImage.classList.remove("loading");
					el.classList.add("loaded");
				} else {
					Auth.currentCredentials().then((currentCredentials) => {
						let s3 = new S3({
							credentials: tempCredentials ? tempCredentials : currentCredentials,
							region: "us-east-1",
							apiVersion: "2006-03-01",
							params: {
								Bucket: elImage.dataset.bucket
							}
						});

						s3.getObject({
							Bucket: elImage.dataset.bucket,
							Key: finalUrl,
							ResponseCacheControl: "no-cache"
						}, function (lazyGetObjectError, file) {
							if (lazyGetObjectError) {
								if (elName) {
									elName.classList.add("fallback-active");
								}

								elImage.dataset.retried = "true";

								//console.log("Lazy Get Image Error.. retry.");
								setTimeout(function() {
									s3.getObject({
										Bucket: elImage.dataset.bucket,
										Key: finalUrl,
										ResponseCacheControl: "no-cache"
									}, function(lazyGetObjectRetryError, retryFile) {
										if (lazyGetObjectRetryError) {
											//console.error("Failed on retry.", lazyGetObjectRetryError);
										} else {
											elImage.classList.remove("loading");
											el.classList.add("loaded");

											if (elName) {
												elName.classList.remove("fallback-active");
											}
											elImage.src = "data:image/png;base64," + encode(retryFile.Body);
										}
									});
								}, 10000);
							} else {
								//console.log("Downloaded lazy image!");
								el.classList.add("loaded");

								elImage.classList.remove("loading");
								if (elName) {
									elName.classList.remove("fallback-active");
								}

								elImage.src = "data:image/png;base64," + encode(file.Body);
							}
						});
					}).catch((reason) => {
						console.error("No Auth for Lazy Image Reason: " + JSON.stringify(reason));
					});
				}

				function encode(data)
				{
					let str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
					return btoa(str).replace(/.{76}(?=.)/g,'$&\n');
				}
			} else {
				console.error("Lazy image dom element not found.");
			}
		}
	}
}
