<template>
    <v-main class="stretch fill-height">
        <v-container fluid style="padding: 20px;" class="stretch fill-height ma-auto">
            <transition name="fade">
                <slot/>
            </transition>
        </v-container>

        <v-footer inset app>
            <v-row style="direction: rtl; padding: 10px 4px;">
                <v-img alt="Zapa Client Portal"
                       src="../assets/images/Zapa-Client-Portals-170.png"
                       style="max-width: 60px; cursor: pointer; margin: 0;"/>
            </v-row>
        </v-footer>
    </v-main>
</template>

<script>
import {VueController} from "@/service/support/vuex-controller";

export default VueController('BaseContainerUnauth', {});
</script>

