<template>
	<div>
		<v-container fluid>
			<small>Toggle to see Yearly rate at 20% Discount !!</small>
			<v-row dense>
				<v-col
            v-for="card in subscriptionPlanOptions" :key="card.title" :cols="card.flex" class="plan-card"
               :class="{ active: card.title === currentPlan && (priceToggle === (stripeInterval === 'year')), loading: inProgress, 'col-12': $vuetify.breakpoint.xsOnly, 'col-4': $vuetify.breakpoint.smAndUp }">
					<a
              @click="selectPlan(card.title, false)">
						<v-card align="center">
							<v-card-title class="pb-1 pt-2" style="justify-content: center" v-text="card.title">
							</v-card-title>
							<v-divider></v-divider>
							<v-card-text>
								<h3 v-if="!priceToggle">{{card.price.monthly}} / Month</h3>
								<h3 v-if="priceToggle">{{card.price.yearly}} / Month</h3>
								<ul v-for="feature in card.features">{{feature}}</ul>
							</v-card-text>
						</v-card>
					</a>
				</v-col>
			</v-row>
            <v-row dense>
                <v-switch v-model="priceToggle" @change="selectPlan(undefined, true)" class="ma-2" :label="priceToggle ? 'Paying Annually': 'Monthly Payments'"></v-switch>
            </v-row>
		</v-container>
	</div>
</template>

<script>
	import {VueController} from "@/service/support/vuex-controller";

	export default VueController("PlanSelection", {
		state: [
			"newOrganizationTier",
			"organization",
      "subscriptionPlanOptions",
			"validPromoCode",
        "stripeInterval"
		],
		data(){
		    return {
		        localSelectedPlan : "",
				    priceToggle: false,
          inProgress: false
			}
		},
    props: {
      defaultPlan: {
        type: String,
        default: ''
      }
    },
    computed: {
      currentPlan() {
        let currentPlanString = "";
        switch (this.localSelectedPlan) {
          case "large":
          case "large_firm":
          case "large firm":
          case "Large Firm":
          case "advanced":
          case "Advanced":
            currentPlanString = "Large Firm";
            break;
          case "enterprise":
          case "Enterprise":
            currentPlanString = "Enterprise";
            break;
          case "pro":
          case "professional":
          case "Professional":
            currentPlanString = "Professional";
            break;
        }
        return currentPlanString;
      }
    },
		mounted (){
			this.refresh();
		},
    methods: {
      setInProgress(value) {
        this.inProgress = value;
      },
      setDiscount(discount) {
        this.priceToggle = discount;
      },
      updateNoPlan() {
        this.localSelectedPlan = "";
        this.priceToggle = false;
      },
      refresh() {
        this.validPromoCode = '';
        this.priceToggle = this.stripeInterval.toLowerCase() !== "month";

        //console.log("Local plan is " + this.localSelectedPlan);
        if (this.defaultPlan === "none") {
          this.localSelectedPlan = "";
        } else if (this.defaultPlan) {
          this.localSelectedPlan = this.defaultPlan;
        } else {
          if (this.organization && this.organization.tier !== 'null') {
            this.localSelectedPlan = this.organization.tier;
          } else {
            this.localSelectedPlan = this.newOrganizationTier;
          }
          if (!this.localSelectedPlan) {
            this.localSelectedPlan = "Professional";
          }
        }
      },
			selectPlan(planName, onlyDiscount) {
        if (!this.inProgress) {
          if (planName) {
            this.localSelectedPlan = planName;
          }

          this.update("stripeDiscount", this.priceToggle);
          this.update("newOrganizationTier",  this.localSelectedPlan.toLowerCase());
          //console.log("Discount = " + this.priceToggle + " SelectedPlan = " + this.localSelectedPlan);
          if (!onlyDiscount) {
            this.$emit('newPlanSelected', {
              plan: this.localSelectedPlan, discount: this.priceToggle, onlyDiscount
            });
          }
        }
			}
		}
	});
</script>

<style lang="scss" scoped>
	.plan-card.active a div.v-card {
    border: 2px solid #0D7542;
	}

  .loading {
    opacity: .4;
  }
</style>
